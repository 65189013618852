import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class TableHeading extends Component {
    render() {
        return (
            <div className='flex justify-start items-center text-xs text-white bg-[#041737] min-w-full w-fit text-center font-semibold border border-gray-400'>
                <div className='border-r border-gray-400 w-[60px] px-3 py-2 flex-grow-0 flex-shrink-0'>{this.props.t("Table.S.No.")}</div>
                <div className='border-r border-gray-400 px-3 py-2 w-full min-w-[60px] flex-shrink'><span className='line-clamp-1'>{this.props.t("Table.Shop Name")}</span></div>
                <div className='border-r border-gray-400 w-[200px] px-3 py-2 flex-grow-0 flex-shrink-0 text-center'><span className='line-clamp-1'>{this.props.t("Table.Email")}</span></div>
                <div className='border-r border-gray-400 px-3 py-2 w-[300px] flex-shrink'><span className='line-clamp-1'>{this.props.t("Table.Seller Name")}</span></div>
                <div className='border-r border-gray-400 w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0 text-center'><span className='line-clamp-1'>{this.props.t("Table.Contact")}</span></div>
                <div className='border-r border-gray-400 w-[200px] px-3 py-2 flex-grow-0 flex-shrink-0 text-center'><span className='line-clamp-1'>{this.props.t("Table.Actions")}</span></div>
                {/* <div className='border-r border-gray-400 w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0'>Orders</div>
                <div className='border-r border-gray-400 w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0'>Revenue</div>
                <div className='border-r border-gray-400 w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0'>Growth</div>
                <div className='w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0'>Rating</div> */}
            </div>
        )
    }
}
export default withTranslation()(TableHeading);