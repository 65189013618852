import React, { Component } from 'react'
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { logout } from '../../actions';

class ShippingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            state: '',
            town_city: '',
            flat_house_building: '',
            area: '',
            landmark: '',
            pincode: '',
            address_type: '',
            shipping_default_address: false,
            copysameaddress: false,
            getButtonDisbled: false

        }
    }
    sendDataToParent = () => {
        if (this.state.state === "") {
            toast.error("Please enter State Name");
        }
        else if (this.state.town_city === "") {
            toast.error("Please enter Town/City");
        }
        else if (this.state.flat_house_building === "") {
            toast.error("Please enter your Flat/House/Building");
        }
        else if (this.state.area === "") {
            toast.error("Please enter your Area");
        }
        else if (this.state.landmark === "") {
            toast.error("Please enter your Landmark");
        }
        else if (this.state.pincode === "") {
            toast.error("Please enter a pincode");
        }
        else if (this.state.address_type === "") {
            toast.error("Please enter your Address Type");
        }
        else {
            this.setState({ submitButtonDisbled: true });
            const data = {
                state: this.state.state,
                town_city: this.state.town_city,
                flat_house_building: this.state.flat_house_building,
                area: this.state.area,
                landmark: this.state.landmark,
                pincode: this.state.pincode,
                address_type: this.state.address_type,
                shipping_default_address: this.state.shipping_default_address,
            };
            console.log('Calling sendDataToParent', data);
            this.props.verifyShippingDetails(data);
        }
    };
    handleChange = (e) => {
        this.setState({ copysameaddress: e.target.checked })

    }
    render() {
        const copyAddress = () => {
            this.setState({ getButtonDisbled: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");
            let id = this.props.params;
            let seller_id_same;
            this.props.seller_data.sellers_addresses.forEach(addr => {
                if (addr.billing_status) {
                    seller_id_same = addr.sellers_address_id
                }
            })
            var raw = JSON.stringify({
                sellers_address_id: seller_id_same,
                shipping_default_address: this.state.copysameaddress
            });
            // console.log(raw);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/add_shipping_address/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === 'Success' || result.status === "success") {
                            toast.success(result.msg)
                            this.setState({ getButtonDisbled: false });
                            // this.setState({ state: result.data.state, town_city: result.data.town_city, flat_house_building: result.data.flat_house_building, area: result.data.area, landmark: result.data.landmark, pincode: result.data.pincode, address_type: result.data.address_type })
                            this.props.changeOnboardingState('Return', 5);
                        }
                        else {
                            toast.error(result.msg)
                            this.setState({ getButtonDisbled: false });
                        }
                    }
                })
                .catch((error) => {
                    toast.error("Server Error")
                });
        }
        return (
            <div className='w-full p-3 justify-center bg-white rounded-md'>
                <div className='text-lg font-semibold'>{this.props.t("ShippingForm.Verify Shipping Details")}</div>
                <div className='text-xs text-gray-400 mb-6'>{this.props.t("ShippingForm.Verify Shipping Details1")}. </div>
                {/* <div className='my-3 flex flex-row  items-center'>
                    <input type="checkbox" name="termsacept" id="termsaccept"
                        checked={this.state.copysameaddress}
                        onChange={this.handleChange} />
                    <div className='text-xs font-semibold text-black ml-2'>Copy Billing Address</div>
                </div> */}
                <div className='my-3'>
                    <button
                        type="submit"
                        className={`w-fit bg-[#ffd814] text-black font-semibold py-1 px-8 text-center rounded-full shadow-md text-sm ${this.state.getButtonDisbled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                        onClick={() => copyAddress()}
                        disabled={this.state.getButtonDisbled}
                    >
                        <div className={`${!this.state.getButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            {this.props.t("ShippingForm.Get Same Address")}
                        </div>
                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            <ThreeDots
                                height="32"
                                width="30"
                                radius="9"
                                color="#000"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    </button>
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("ShippingForm.State")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("ShippingForm.Town/City")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.town_city} onChange={(e) => this.setState({ town_city: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("ShippingForm.Flat/House/Building")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.flat_house_building} onChange={(e) => this.setState({ flat_house_building: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("ShippingForm.Area")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.area} onChange={(e) => this.setState({ area: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("ShippingForm.Landmark")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.landmark} onChange={(e) => this.setState({ landmark: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("ShippingForm.pincode")}</div>
                    <input type="pincode" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.pincode} onChange={(e) => this.setState({ pincode: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("ShippingForm.Address Type")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.address_type} onChange={(e) => this.setState({ address_type: e.target.value })} />
                </div>
                <div className='my-3 flex flex-row  items-center'>
                    <input type="checkbox" name="termsacept" id="termsaccept"
                        checked={this.state.shipping_default_address}
                        onChange={(e) => this.setState({ shipping_default_address: e.target.checked })} />
                    <div className='text-xs font-semibold text-black ml-2'>{this.props.t("ShippingForm.Shipping Default Address")}</div>
                </div>
                <div className='my-3'>
                    <button
                        type="submit"
                        className={`w-fit bg-[#ffd814] text-black font-semibold py-1 px-8 text-center rounded-full shadow-md text-sm ${this.props.submitButtonDisbled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                        onClick={() => this.sendDataToParent()}
                        disabled={this.props.submitButtonDisbled}
                    >
                        <div className={`${!this.props.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            {this.props.t("Submit")}
                        </div>
                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.props.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            <ThreeDots
                                height="32"
                                width="30"
                                radius="9"
                                color="#000"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    </button>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};

export default connect(null, mapDispatchToProps)(withTranslation()(ShippingForm));