import { AGENT_INFO, LOGIN, SELLERS } from "../constants";
import CryptoJS from "crypto-js";

export const setLogin = (token) => {
  return async function (dispatch) {
    dispatch({
      type: LOGIN,
      payload: token,
    });
  };
};
export const logout = () => {
  return async function (dispatch) {
    window.localStorage.removeItem('dukkandaar_seller_hub');
    window.location.replace("/signin");
    dispatch({
      type: LOGIN,
      payload: null,
    });
    dispatch({
      type: AGENT_INFO,
      payload: null,
    });
  };
};
export const getAgentInfo = (token) => {
  return async function (dispatch) {
    var myHeaders = new Headers();
    myHeaders.append("token", token);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_URL}/agent_info`, requestOptions)
      .then(response => response.json())
      .then(res => {
        if (res.code === 401 || res.code === 407) {
          dispatch(logout());
        }
        else {
          if (res.status === "Success" || res.status === "success") {
            const ciphertext = res.data;
            const bytes = CryptoJS.AES.decrypt(
              ciphertext,
              process.env.REACT_APP_SECRET_KEY
            );
            const plaintext = bytes.toString(CryptoJS.enc.Utf8);
            var dt = JSON.parse(plaintext);
            dispatch({
              type: AGENT_INFO,
              payload: dt,
            });
          }
          else {
            dispatch(logout());
          }
        }
      })
      .catch(error => console.log('error', error));
  }
}

export const getSellers = (token, page, number, search, type, order, body) => {
  return async function (dispatch) {
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    if (body) {
      var raw = JSON.stringify(body);
      requestOptions.body = raw;
    }
    else {
      body = null;
    }
    let url = `${process.env.REACT_APP_API_URL}/get_sellers/${page}?${search !== null ? `search=${search}&` : ''}${number !== null ? `number=${number}&` : ''}${type !== null ? `type=${type}&` : ''}${order !== null ? `order=${order}&` : ''}`;
    // console.log(url);
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.code === 401 || result.code === 407) {
          dispatch(logout());
        }
        else {
          if (result.status === "Success" || result.status === "success") {
            let sellers_data = {
              status: "success",
              msg: "",
              sellers: result.seller_data,
              total: result.total_items
            }
            dispatch({
              type: SELLERS,
              payload: sellers_data,
            });
          }
          else {
            let sellers_data = {
              status: "failure",
              msg: result.msg,
              sellers: [],
              total: 0
            }
            dispatch({
              type: SELLERS,
              payload: sellers_data,
            });
          }
        }
      })
      .catch(error => console.log('error', error));
  }
}