import React, { Component } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';
import DashboardCard from '../Components/DashboardCard';
import Table from '../Components/Table/Table'
import SellerCard from '../Components/SellerCard';
import { connect } from 'react-redux';
import { BiSearchAlt2, BiX } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr'
import { Years } from '../Utils/Years';
import { Months } from '../Utils/Months';
import { getSellers } from '../actions';
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AccessDenied from '../Components/ProtectRoutes/AccessDenied';

const withRouter = WrappedComponent => props => {
    const location = useLocation();

    return (
        <WrappedComponent
            {...props}
            location={location}
        />
    );
};
class Sellers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            query: "",
            number: "",
            type: "",
            order: "all",
            year: 2000,
            month: 1,
            start: '2000-01-01',
            end: '2000-01-31',
            querySearch: false,
            numberSearch: false,
            filtersRecieved: false,
            data: [],
            User: [],
        }
    }
    componentDidMount() {
        if (this.props.token && this.state.data.length === 0) {
            this.fetchSellers();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.state && this.props.token && !this.state.filtersRecieved) {
            let filters = this.props.location.state.filters;
            this.setState({
                page: filters.page,
                query: filters.query,
                number: filters.number,
                type: filters.type,
                order: filters.order,
                year: filters.year,
                month: filters.month,
                start: filters.start,
                end: filters.end,
                querySearch: filters.querySearch,
                numberSearch: filters.numberSearch,
                filtersRecieved: true
            });
            console.log(filters);
            let body = null;
            if (filters.type === "Month") {
                body = {
                    month: filters.month,
                    year: filters.year
                }
            }
            else if (filters.type === "Year") {
                body = {
                    year: filters.year
                }
            }
            else if (filters.type === "Custom") {
                body = {
                    start: filters.start,
                    end: filters.end
                }
            }
            this.props.getSellersDispatch({
                token: this.props.token,
                page: filters.page,
                number: (filters.number !== '' ? filters.number : null),
                search: (filters.query !== '' ? filters.query : null),
                type: (filters.type !== '' ? filters.type : null),
                order: (filters.order !== 'all' ? filters.order : null),
                body: body
            });
        }
        if (
            this.state.page !== prevState.page ||
            this.state.type !== prevState.type ||
            this.state.order !== prevState.order ||
            this.state.year !== prevState.year ||
            this.state.month !== prevState.month ||
            this.state.start !== prevState.start ||
            this.state.end !== prevState.end
        ) {
            let body = null;
            if (this.state.type === "Month") {
                body = {
                    month: this.state.month,
                    year: this.state.year
                }
            }
            else if (this.state.type === "Year") {
                body = {
                    year: this.state.year
                }
            }
            else if (this.state.type === "Custom") {
                body = {
                    start: this.state.start,
                    end: this.state.end
                }
            }
            this.props.getSellersDispatch({
                token: this.props.token,
                page: this.state.page,
                number: (this.state.number !== "" ? this.state.number : null),
                search: (this.state.query !== "" ? this.state.query : null),
                type: (this.state.type !== "" ? this.state.type : null),
                order: (this.state.order !== "all" ? this.state.order : null),
                body: body
            });
        }
    }
    componentWillUnmount() {
        this.props.getSellersDispatch({
            token: this.props.token,
            page: 1,
            number: null,
            search: null,
            type: null,
            order: null,
            body: null
        })
    }
    fetchSellers = () => {
        var myHeaders = new Headers();
        myHeaders.append("token", this.props.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_URL}/seller_analytics`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // if (result.code === 401 || result.code === 407) {
                //     this.props.logoutDispatch();
                // }
                // else {
                // if (result.status === "Success" || result.status === "success") {
                this.setState({
                    User: [result]
                });
                // }
                // }
            })
            .catch(error => console.log('error', error));
    }
    render() {
        let breadcrumbs = [
            {
                name: 'Sellers',
                link: '/sellers'
            }
        ]
        const searchByQuery = () => {
            if (this.state.query === "") {
                toast.error("Please enter search query!")
            }
            else {
                this.setState({ querySearch: true });
                let body = null;
                if (this.state.type === "Month") {
                    body = {
                        month: this.state.month,
                        year: this.state.year
                    }
                }
                else if (this.state.type === "Year") {
                    body = {
                        year: this.state.year
                    }
                }
                else if (this.state.type === "Custom") {
                    body = {
                        start: this.state.start,
                        end: this.state.end
                    }
                }
                this.props.getSellersDispatch({
                    token: this.props.token,
                    page: this.state.page,
                    number: (this.state.number !== "" ? this.state.number : null),
                    search: this.state.query,
                    type: (this.state.type !== "" ? this.state.type : null),
                    order: (this.state.order !== "all" ? this.state.order : null),
                    body: body
                });
            }
        }
        const searchByNumber = () => {
            if (this.state.number === "") {
                toast.error("Please enter number!")
            }
            else {
                this.setState({ numberSearch: true });
                let body = null;
                if (this.state.type === "Month") {
                    body = {
                        month: this.state.month,
                        year: this.state.year
                    }
                }
                else if (this.state.type === "Year") {
                    body = {
                        year: this.state.year
                    }
                }
                else if (this.state.type === "Custom") {
                    body = {
                        start: this.state.start,
                        end: this.state.end
                    }
                }
                this.props.getSellersDispatch({
                    token: this.props.token,
                    page: this.state.page,
                    number: this.state.number,
                    search: (this.state.query !== "" ? this.state.query : null),
                    type: (this.state.type !== "" ? this.state.type : null),
                    order: (this.state.order !== "all" ? this.state.order : null),
                    body: body
                });
            }
        }
        const resetQuerySearch = () => {
            this.setState({ querySearch: false, query: "" });
            let body = null;
            if (this.state.type === "Month") {
                body = {
                    month: this.state.month,
                    year: this.state.year
                }
            }
            else if (this.state.type === "Year") {
                body = {
                    year: this.state.year
                }
            }
            else if (this.state.type === "Custom") {
                body = {
                    start: this.state.start,
                    end: this.state.end
                }
            }
            this.props.getSellersDispatch({
                token: this.props.token,
                page: this.state.page,
                number: (this.state.number !== "" ? this.state.number : null),
                search: null,
                type: (this.state.type !== "" ? this.state.type : null),
                order: (this.state.order !== "all" ? this.state.order : null),
                body: body
            });
        }
        const resetNumberSearch = () => {
            this.setState({ numberSearch: false, number: "" });
            let body = null;
            if (this.state.type === "Month") {
                body = {
                    month: this.state.month,
                    year: this.state.year
                }
            }
            else if (this.state.type === "Year") {
                body = {
                    year: this.state.year
                }
            }
            else if (this.state.type === "Custom") {
                body = {
                    start: this.state.start,
                    end: this.state.end
                }
            }
            this.props.getSellersDispatch({
                token: this.props.token,
                page: this.state.page,
                number: null,
                search: (this.state.query !== "" ? this.state.query : null),
                type: (this.state.type !== "" ? this.state.type : null),
                order: (this.state.order !== "all" ? this.state.order : null),
                body: body
            });
        }
        const changePage = (pageNumber) => {
            this.setState({ page: pageNumber });
        }
        return (
            <>
                {this.props.token ? (
                    <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                        <div className='w-fit h-fit z-50 fixed'>
                            <Sidebar />
                        </div>
                        <div className='w-full md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
                            <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                                <Navbar breadcrumbs={breadcrumbs} />
                            </div>
                            <div className='px-3 mt-[80px] md:mt-[50px] mb-7 md:mb-0'>
                                <div className='flex-shrink-0 flex-grow-0 flex flex-col overflow-y-auto overflow-x-hidden'>
                                {this.state.User.map((item, index) => (
                                      <div className='w-full flex flex-row justify-start items-center gap-3 overflow-x-auto custom-scrollbar-horizontal flex-shrink-0 flex-grow-0'>
                                       {Object.entries(item).map(([key, value]) => (
                                         key !== 'status' && <SellerCard status={this.props.t(`Sellers.${key}`)} number={value} />
                                         ))}
                                         </div>
                                     ))}
                                    <div className='w-full rounded-md bg-white p-3 mt-3 mb-[50px]'>
                                        <div className='text-lg font-semibold'>{this.props.t("Sellers.All Sellers")}</div>
                                        <div className='text-xs text-gray-400'>{this.props.t("Sellers.All Sellers1")}.</div>
                                        <div className='w-full flex flex-wrap justify-start items-center gap-3 my-3'>
                                            <div className='flex justify-center items-stretch'>
                                                <input type="text" placeholder='Search Query' className='border border-gray-500 text-sm px-2 py-1 rounded-tl-md rounded-bl-md' value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} />
                                                {
                                                    this.state.querySearch ?
                                                        <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tr-md rounded-br-md cursor-pointer text-white' onClick={() => resetQuerySearch()}>
                                                            <BiX color="#ffffff" size={15} />
                                                        </div> :
                                                        <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tr-md rounded-br-md cursor-pointer text-white' onClick={() => searchByQuery()}>
                                                            <BiSearchAlt2 color="#ffffff" size={15} />
                                                        </div>
                                                }
                                            </div>
                                            <div className='flex justify-center items-stretch'>
                                                <input type="number" placeholder='Search by number' className='border border-gray-500 text-sm px-2 py-1 rounded-tl-md rounded-bl-md' value={this.state.number} onChange={(e) => this.setState({ number: e.target.value })} />
                                                {
                                                    this.state.numberSearch ?
                                                        <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tr-md rounded-br-md cursor-pointer text-white' onClick={() => resetNumberSearch()}>
                                                            <BiX color="white" size={15} />
                                                        </div> :
                                                        <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tr-md rounded-br-md cursor-pointer text-white' onClick={() => searchByNumber()}>
                                                            <BiSearchAlt2 color="#ffffff" size={15} />
                                                        </div>
                                                }
                                            </div>
                                            <div className='flex justify-center items-stretch'>
                                                <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tl-md rounded-bl-md cursor-pointer text-white text-sm'>
                                                    {this.props.t("Sellers.Order")}
                                                </div>
                                                <select className='border border-gray-500 text-sm px-2 py-1 rounded-tr-md rounded-br-md' value={this.state.order} onChange={(e) => this.setState({ order: e.target.value })}>
                                                    <option value="all">{this.props.t("Sellers.All")}</option>
                                                    <option value="latest">{this.props.t("Sellers.Latest")}</option>
                                                </select>
                                            </div>
                                            <div className='flex justify-center items-stretch'>
                                                <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tl-md rounded-bl-md cursor-pointer text-white text-sm'>
                                                    {this.props.t("Sellers.Type")}
                                                </div>
                                                <select className='border border-gray-500 text-sm px-2 py-1 rounded-tr-md rounded-br-md' value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })}>
                                                    <option value="">{this.props.t("Sellers.Select")}</option>
                                                    <option value="Today">{this.props.t("Sellers.Today")}</option>
                                                    <option value="L7D">{this.props.t("Sellers.Last 7 Days")}</option>
                                                    <option value="Month">{this.props.t("Sellers.Month")}</option>
                                                    <option value="Year">{this.props.t("Sellers.Year")}</option>
                                                    <option value="Custom">{this.props.t("Sellers.Custom")}</option>
                                                </select>
                                            </div>
                                            <div className='flex justify-start items-center gap-3'>
                                                {
                                                    (this.state.type === "Month" || this.state.type === "Year") &&
                                                    <div className='flex justify-center items-stretch'>
                                                        <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tl-md rounded-bl-md cursor-pointer text-white text-sm'>
                                                            {this.props.t("Sellers.Year")}
                                                        </div>
                                                        <select className='border border-gray-500 text-sm px-2 py-1 rounded-tr-md rounded-br-md' value={this.state.year} onChange={(e) => this.setState({ year: e.target.value })}>
                                                            {Years.map(year => <option value={year}>{year}</option>)}
                                                        </select>
                                                    </div>
                                                }
                                                {
                                                    this.state.type === "Month" &&
                                                    <div className='flex justify-center items-stretch'>
                                                        <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tl-md rounded-bl-md cursor-pointer text-white text-sm'>
                                                            {this.props.t("Sellers.Month")}
                                                        </div>
                                                        <select className='border border-gray-500 text-sm px-2 py-1 rounded-tr-md rounded-br-md' value={this.state.month} onChange={(e) => this.setState({ month: e.target.value })}>
                                                            {Months.map((month, i) => <option value={i + 1}>{month}</option>)}
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                this.state.type === "Custom" &&
                                                <>
                                                    <div className='flex justify-center items-stretch'>
                                                        <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tl-md rounded-bl-md cursor-pointer text-white text-sm'>
                                                            {this.props.t("Sellers.Start")}
                                                        </div>
                                                        <input type="date" name="" id="" className='border border-gray-500 text-sm px-2 py-1 rounded-tr-md rounded-br-md' value={this.state.start} onChange={(e) => this.setState({ start: e.target.value })} />
                                                    </div>
                                                    <div className='flex justify-center items-stretch'>
                                                        <div className='flex justify-center items-center px-2 bg-[#041737] rounded-tl-md rounded-bl-md cursor-pointer text-white text-sm'>
                                                            {this.props.t("Sellers.End")}
                                                        </div>
                                                        <input type="date" name="" id="" className='border border-gray-500 text-sm px-2 py-1 rounded-tr-md rounded-br-md' value={this.state.end} onChange={(e) => this.setState({ end: e.target.value })} />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        {
                                            (this.state.querySearch || this.state.numberSearch || this.state.type !== "" || this.state.order !== "all") &&
                                            <div className='my-3 border p-3'>
                                                <div></div>
                                                <div className='text-sm font-semibold mb-3'>{this.props.t("Sellers.Filters")}</div>
                                                <div className='w-full flex flex-wrap justify-start items-center gap-3 '>
                                                    {
                                                        this.state.querySearch &&
                                                        <div className='px-2 py-1 bg-white rounded-full border border-black text-xs cursor-pointer flex justify-center items-center font-semibold' onClick={() => resetQuerySearch()}>
                                                            <div>{this.props.t("Sellers.Search")}: {this.state.query}</div>
                                                            <GrClose color="black" className='ml-1' size={10} />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.numberSearch &&
                                                        <div className='px-2 py-1 bg-white rounded-full border border-black text-xs cursor-pointer flex justify-center items-center font-semibold' onClick={() => resetNumberSearch()}>
                                                            <div>{this.props.t("Sellers.Number")}: {this.state.number}</div>
                                                            <GrClose color="black" className='ml-1' size={10} />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.type !== "" &&
                                                        <div className='px-2 py-1 bg-white rounded-full border border-black text-xs cursor-pointer flex justify-center items-center font-semibold' onClick={() => this.setState({ type: "" })}>
                                                            <div>{this.props.t("Sellers.Type")}: {this.state.type === "L7D" ? 'Last 7 Days' : this.state.type}</div>
                                                            <GrClose color="black" className='ml-1' size={10} />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.order !== "all" &&
                                                        <div className='px-2 py-1 bg-white rounded-full border border-black text-xs cursor-pointer flex justify-center items-center font-semibold' onClick={() => this.setState({ order: "all" })}>
                                                            <div>{this.props.t("Sellers.Order")}: {this.state.order}</div>
                                                            <GrClose color="black" className='ml-1' size={10} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className='overflow-x-auto custom-scrollbar-horizontal pb-1 mt-3'>
                                            {
                                                this.props.seller_data && <Table filters={this.state} seller_data={this.props.seller_data} sellers={this.props.seller_data.sellers} paginationAllowed={true} changePage={changePage} currentPage={this.state.page} totalPages={Math.ceil(this.props.seller_data.total / 20)} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full mt-3 absolute bottom-0'>
                                <Footer />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
        agent_info: state.agent_info,
        seller_data: state.seller_data
    };
};
const mapDispatchToProps = (dispatch) => {
    const getSellersDispatch = (data) => {
        dispatch(getSellers(data.token, data.page, data.number, data.search, data.type, data.order, data.body));
    }
    return {
        getSellersDispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Sellers)));