import React, { Component } from 'react'
import logo from '../Assets/logo.png'
import { Link, Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ThreeDots } from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from '../actions';

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credentialsAdded: false,
            credentials: "",
            otp: "",
            loggedIn: false,
            password: '',
            updateButtonDisabled: false,
            PasswordButtonDisbled: false
        }
    }
    render() {
        const sendOTP = () => {
            if (this.state.credentials !== '') {
                this.setState({ updateButtonDisabled: true })
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    email: this.state.credentials
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_API_URL}/forgt_pass_sendOtp`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 401 || result.code === 407) {
                            this.props.logoutDispatch();
                        }
                        else {
                            if (result.status === "Success" || result.status === "success") {
                                toast.success(result.msg)
                                this.setState({ credentialsAdded: true, updateButtonDisabled: false })
                            }
                            else {
                                toast.error(result.msg)
                                this.setState({ updateButtonDisabled: false })
                            }
                        }
                    })
                    .catch((error) => {
                        this.setState({ updateButtonDisabled: false });
                        toast.error("Server Error")
                    });
            }
            else {
                toast.error();
                setTimeout(() => {
                    this.setState({ updateButtonDisabled: false })
                }, 1500);
            }
        }
        const reset = () => {
            this.setState({
                credentialsAdded: false,
                credentials: "",
                otp: "",
                loggedIn: false
            })
        }
        const submit = () => {
            this.setState({ PasswordButtonDisbled: true })
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                email: this.state.credentials,
                OTP: this.state.otp,
                password: this.state.password
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/reset_password`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success(result.msg)
                            this.setState({ PasswordButtonDisbled: false, loggedIn: true })
                        }
                        else {
                            toast.error(result.msg)
                            this.setState({ PasswordButtonDisbled: false })
                            window.location.href = ('/reset-password')
                        }
                    }
                }
                )
                .catch((error) => {
                    this.setState({ PasswordButtonDisbled: false });
                    toast.error("Server Error")
                });
        }
        return (
            <div className='w-full max-w-[500px] px-5 py-4 m-auto'>
                {
                    this.state.loggedIn && <Navigate to={'/signin'} />
                }
                <img src={logo} className='w-[150px] m-auto' alt="" />
                <div className='w-full mt-4 py-4 px-8 rounded-2xl border-2 border-black'>
                    <div className='font-medium text-2xl'>{this.props.t("ForgotPassword.Reset Password")}</div>
                    {
                        !this.state.credentialsAdded ?
                            <>
                                <div className='my-4'>
                                    <div className='text-xs text-gray-500 font-medium'>{this.props.t("ForgotPassword.Email")}</div>
                                    <input type="email" className='border border-black rounded-md w-full p-2 text-sm mt-1' placeholder='Enter registered Email' value={this.state.credentials} onChange={(e) => this.setState({ credentials: e.target.value })} />
                                </div>
                                <div className='my-4'>
                                    <button
                                        className={`bg-[#FFD814] w-full text-center py-2 text-sm font-semibold rounded-full shadow-md ${this.state.updateButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                                        onClick={() => sendOTP()}
                                        disabled={this.state.updateButtonDisabled}
                                    >
                                        <div className={`${!this.state.updateButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            {this.props.t("Sent OTP")}
                                        </div>
                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.updateButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            <ThreeDots
                                                height="32"
                                                width="30"
                                                radius="9"
                                                color="#000"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    </button>
                                </div>
                            </> :
                            <>
                                <div className='my-4'>
                                    <div className='flex justify-between items-center flex-row'>
                                        <div className='flex flex-row justify-start items-center'>
                                            <div className='text-xs text-gray-500 font-semibold'>{this.props.t("ForgotPassword.Email")}: {this.state.credentials}</div>
                                            <div className='text-[#5aa3b0] font-semibold text-xs cursor-pointer ml-3' onClick={() => reset()}>{this.props.t("ForgotPassword.Change")}</div>
                                        </div>
                                    </div>

                                    <input type="text" className='border border-black rounded-md w-full p-2 text-sm mt-1' placeholder='Enter Password' value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                    <input type="text" className='border border-black rounded-md w-full p-2 text-sm mt-1' placeholder='Enter OTP Received' value={this.state.otp} onChange={(e) => this.setState({ otp: e.target.value })} />
                                </div>
                                <div className='my-4'>
                                    <button
                                        type="submit"
                                        className={`bg-[#FFD814] w-full text-center py-2 text-sm font-semibold rounded-full shadow-md ${this.state.PasswordButtonDisbled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                                        onClick={() => submit()}
                                    >
                                        <div className={`${!this.state.PasswordButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            {this.props.t("Submit")}
                                        </div>
                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.PasswordButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            <ThreeDots
                                                height="32"
                                                width="30"
                                                radius="9"
                                                color="#000"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    </button>
                                </div>
                            </>
                    }
                    <div className='my-4'>
                        <div className='text-gray-500 text-sm'>{this.props.t("ForgotPassword.By continuing")} <span className='text-blue-800 cursor-pointer'>{this.props.t("ForgotPassword.Conditions of Use")}</span> {this.props.t("ForgotPassword.and")} <span className='text-blue-800 cursor-pointer'>{this.props.t("ForgotPassword.Privacy Notice")}</span>.</div>
                    </div>
                </div>
                <div className='flex mt-2 flex-row justify-around'>
                    <div className='text-sm text-[#5aa3b0] font-medium'>{this.props.t("ForgotPassword.Conditions of Use")}</div>
                    <div className='text-sm text-[#5aa3b0] font-medium'>{this.props.t("ForgotPassword.Privacy Notice")}</div>
                    <div className='text-sm text-[#5aa3b0] font-medium'>{this.props.t("ForgotPassword.Help")}</div>
                </div>
                <div className='text-sm text-gray-700 text-center'>
                    {this.props.t("@")}
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};
export default connect(null, mapDispatchToProps)(withTranslation()(ForgetPassword));