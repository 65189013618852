import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';

class Terms extends Component {
    render() {
        let breadcrumbs = [
            {
                name: 'Terms',
                link: '/terms-condition'
            }
        ]
        return (
            <div className=' -row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                <div className='w-fit h-fit z-50 fixed'>
                    <Sidebar />
                </div>
                <div className='w-full md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
                    <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                        <Navbar breadcrumbs={breadcrumbs} />
                    </div>
                    <div className='px-3 mt-[80px] md:ml-[10px] md:mt-[50px] mb-7 md:mb-0'>
                        <div className='font-semibold text-xl'>{this.props.t("Terms.Terms of Use")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Terms of Use1")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Terms of Use2")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Terms of Use3")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Terms of Use4")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Terms of Use5")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Terms of Use6")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Terms of Use7")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Eligibility for Sellers")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Eligibility for Sellers1")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Account and Registration Obligations")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Account and Registration Obligations1")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Info")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info1")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info2")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info3")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info4")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info5")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info6")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info7")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info8")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info9")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info10")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info11")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info12")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info13")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info14")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info15")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info16")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info17")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info18")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info19")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info20")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info21")}</li>
                                <li className='my-2'>{this.props.t("Terms.Info22")}</li>
                            </ol>
                        </div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Account and Registration Obligations2")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Account and Registration Obligations3")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Account and Registration Obligations4")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Seller Account Deactivation")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Seller Account Deactivation1")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Communication")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Communication1")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Platform for Communications")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Platform for Communications1")}</div>
                        <div className='my-2'>
                            <ol className='list-decimal ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Platform for Communications2")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform for Communications3")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform for Communications4")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform for Communications5")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform for Communications6")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform for Communications7")}</li>
                            </ol>
                        </div>
                        <div className='font-semibold'>{this.props.t("Terms.Platform Services")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Platform Services1")}</div>
                        <div className='my-2'>
                            <ol className='list-decimal ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Use of the Platform3")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services3")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services4")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services5")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services6")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services7")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services8")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services9")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services10")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services11")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services12")}</li>
                                <li className='my-2'>{this.props.t("Terms.Platform Services13")}</li>
                            </ol>
                        </div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Platform Services14")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Use of the Platform")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Use of the Platform1")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Use of the Platform2")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Use of the Platform3")}</div>
                        <div className='my-2'>
                            <ol className='list-decimal ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Use of the Platform4")}</li>
                                <div className='my-2'>
                                    <ol className='list-disc ml-4 text-sm'>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform5")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform6")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform7")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform8")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform9")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform10")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform11")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform12")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform13")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform14")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform15")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform16")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform17")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform18")}</li>
                                        <li className='my-2'>{this.props.t("Terms.Use of the Platform19")}</li>
                                    </ol>
                                </div>
                            </ol>
                        </div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Use of the Platform20")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Platform Usage Restrictions")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Platform Usage Restrictions1")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Platform Usage Restrictions2")}</div>
                        <div className='my-2'>
                            <ol className='list-decimal ml-4 text-sm'>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities1")}</div><div className='my-1'>{this.props.t("Terms.activities2")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities3")}</div><div className='my-1'>{this.props.t("Terms.activities4")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities5")}</div><div className='my-1'>{this.props.t("Terms.activities6")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities7")}</div><div className='my-1'>{this.props.t("Terms.activities8")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities9")}</div><div className='my-1'>{this.props.t("Terms.activities10")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities11")}</div><div className='my-1'>{this.props.t("Terms.activities12")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities13")}</div><div className='my-1'>{this.props.t("Terms.activities14")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities15")}</div><div className='my-1'>{this.props.t("Terms.activities16")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities17")}</div><div className='my-1'>{this.props.t("Terms.activities18")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities19")}</div><div className='my-1'>{this.props.t("Terms.activities20")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities21")}</div><div className='my-1'>{this.props.t("Terms.activities22")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities23")}</div><div className='my-1'>{this.props.t("Terms.activities24")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities25")}</div><div className='my-1'>{this.props.t("Terms.activities26")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities27")}</div><div className='my-1'>{this.props.t("Terms.activities28")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities29")}</div><div className='my-1'>{this.props.t("Terms.activities30")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities31")}</div><div className='my-1'>{this.props.t("Terms.activities32")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities33")}</div><div className='my-1'>{this.props.t("Terms.activities34")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities35")}</div><div className='my-1'>{this.props.t("Terms.activities36")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities37")}</div><div className='my-1'>{this.props.t("Terms.activities38")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities39")}</div><div className='my-1'>{this.props.t("Terms.activities40")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities41")}</div><div className='my-1'>{this.props.t("Terms.activities42")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities43")}</div><div className='my-1'>{this.props.t("Terms.activities44")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities45")}</div><div className='my-1'>{this.props.t("Terms.activities46")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities47")}</div><div className='my-1'>{this.props.t("Terms.activities48")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities49")}</div><div className='my-1'>{this.props.t("Terms.activities50")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities51")}</div><div className='my-1'>{this.props.t("Terms.activities52")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities53")}</div><div className='my-1'>{this.props.t("Terms.activities54")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities55")}</div><div className='my-1'>{this.props.t("Terms.activities56")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities57")}</div><div className='my-1'>{this.props.t("Terms.activities58")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities59")}</div><div className='my-1'>{this.props.t("Terms.activities60")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.activities61")}</div><div className='my-1'>{this.props.t("Terms.activities62")}</div></div></li>
                            </ol>
                        </div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.activities63")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Selling")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Selling1")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Selling2")}</div>
                        <div className='my-2'>
                            <ol className='list-decimal ml-4 text-sm'>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling3")}</div><div className='my-1'>{this.props.t("Terms.Selling4")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling5")}</div><div className='my-1'>{this.props.t("Terms.Selling6")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling7")}</div><div className='my-1'>{this.props.t("Terms.Selling8")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling9")}</div><div className='my-1'>{this.props.t("Terms.Selling10")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling11")}</div><div className='my-1'>{this.props.t("Terms.Selling12")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling13")}</div><div className='my-1'>{this.props.t("Terms.Selling14")}</div></div></li>
                            </ol>
                        </div>
                        <div className='font-semibold'>{this.props.t("Terms.Selling15")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Selling16")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Selling17")}</div>
                        <div className='my-2'>
                            <ol className='list-decimal ml-4 text-sm'>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling18")}</div><div className='my-1'>{this.props.t("Terms.Selling19")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling20")}</div><div className='my-1'>{this.props.t("Terms.Selling21")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling22")}</div><div className='my-1'>{this.props.t("Terms.Selling23")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling24")}</div><div className='my-1'>{this.props.t("Terms.Selling25")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling26")}</div><div className='my-1'>{this.props.t("Terms.Selling27")}</div></div></li>
                            </ol>
                        </div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Selling28")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Income Tax")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Income Tax1")}</li>
                                <li className='my-2'>{this.props.t("Terms.Income Tax2")}</li>
                                <li className='my-2'>{this.props.t("Terms.Income Tax3")}</li>
                                <li className='my-2'>{this.props.t("Terms.Income Tax4")}</li>
                                <li className='my-2'>{this.props.t("Terms.Income Tax5")}</li>
                            </ol>
                        </div>
                        <div className='font-semibold'>{this.props.t("Terms.Content Posted on the Platform")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Content Posted on the Platform1")}</li>
                                <li className='my-2'>{this.props.t("Terms.Content Posted on the Platform2")}</li    >
                                <li className='my-2'>{this.props.t("Terms.Content Posted on the Platform3")}</li>
                            </ol>
                        </div>
                        <div className='font-semibold'>{this.props.t("Terms.Limited License")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Limited License1")}</li>
                            </ol>
                        </div>
                        <div className='font-semibold'>{this.props.t("Terms.Types of Sellers")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Types of Sellers1")}</li>
                                <li className='my-2'>{this.props.t("Terms.Types of Sellers2")}</li>
                                <li className='my-2'>{this.props.t("Terms.Types of Sellers3")}</li>
                            </ol>
                        </div>
                        <div className='font-semibold'>{this.props.t("Terms.Sellers under Samarth Program")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Sellers under Samarth Program1")}</li>
                                <li className='my-2'>{this.props.t("Terms.Sellers under Samarth Program2")}</li>
                                <li className='my-2'>{this.props.t("Terms.Sellers under Samarth Program3")}</li>
                            </ol>
                        </div>
                        <div className='font-semibold'>{this.props.t("Terms.Commercial Terms")}</div>
                        <div className='text-sm'>{this.props.t("Terms.Commercial Terms1")}</div>
                        <div className='border overflow-x-scroll custom-scrollbar-horizontal2 m-4'>
                            <table className='w-full text-sm text-left text-gray-500'>
                                <thead className='text-sm whitespace-nowrap text-gray-700 uppercase bg-gray-50'>
                                    <tr>
                                        <th className='px-6 py-2 md:py-3'>
                                            {this.props.t("Terms.Heading1")}
                                        </th>
                                        <th className='px-6 py-2 md:py-3'>
                                            {this.props.t("Terms.Heading2")}
                                        </th>
                                        <th className='px-6 py-2 md:py-3'>
                                            {this.props.t("Terms.Heading3")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4'>
                                            Up to 1 Cr.
                                        </th>
                                        <td className='px-6 py-2 md:py-4'>
                                            0%
                                        </td>
                                        <td className='px-6 py-2 md:py-4'>
                                            5%
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4'>
                                            1 - 2.5 Cr.
                                        </th>
                                        <td className='px-6 py-2 md:py-4'>
                                            0%
                                        </td>
                                        <td className='px-6 py-2 md:py-4'>
                                            8%
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4'>
                                            More than 2.5 Cr.
                                        </th>
                                        <td className='px-6 py-2 md:py-4'>
                                            Standard Slab / No Rebate
                                        </td>
                                        <td className='px-6 py-2 md:py-4'>
                                            Standard Slab / No Rebate
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='text-sm my-2'>{this.props.t("Terms.note")}</div>
                        <div className='text-sm my-2'>{this.props.t("Terms.note1")}</div>
                        <div className='text-sm my-2'>{this.props.t("Terms.note2")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Payments and Transactions")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Payments and Transactions1")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Payments and Transactions2")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Payments and Transactions3")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Payments and Transactions4")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Payments and Transactions5")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Payments and Transactions6")}</div>
                        <div className='font-semibold'>{this.props.t("Terms.Prepaid Payment Instruments")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments1")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments2")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments3")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments4")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments5")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments6")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments7")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments8")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments9")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments10")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments11")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments12")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments13")}</li>
                                <li className='my-2'>{this.props.t("Terms.Prepaid Payment Instruments14")}</li>
                            </ol>
                        </div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Financial Facilities")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Financial Facilities1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Charges")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Charges1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.GST / Taxes")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.GST / Taxes1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Invoice Generation")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Invoice Generation1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Chargebacks")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Chargebacks1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Payment Confirmation and Delay")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Payment Confirmation and Delay1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Payment Discharge")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Payment Discharge1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Compliance with Laws")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Compliance with Laws1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Compliance with Consumer Protection Rules")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Compliance with Consumer Protection Rules1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Compliance with Legal Metrology Rules")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Compliance with Legal Metrology Rules1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Sharing of Information")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Sharing of Information1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Seller Action Framework")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Seller Action Framework1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Responsibility")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Responsibility1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Product Categorization")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Product Categorization1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Penalties")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Penalties1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Anti-Corruption")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Anti-Corruption1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Certification of Compliance")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Certification of Compliance1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Right to Audit and Obligation to Cooperate")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Right to Audit and Obligation to Cooperate1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Training")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Training1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Subcontractors")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Subcontractors1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Right to Terminate")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Right to Terminate1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Form of Payment")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Form of Payment1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Obligation to Provide Information")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Obligation to Provide Information1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.No Government Interaction")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.No Government Interaction1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Material Change")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Material Change1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Cooperation with Investigations")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Cooperation with Investigations1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Training3")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Training4")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Product Description")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Product Description1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Audits")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Audits1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Breach")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Breach1")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'>{this.props.t("Terms.Breach1")}</li>
                                <li className='my-2'>{this.props.t("Terms.Breach2")}</li>
                                <li className='my-2'>{this.props.t("Terms.Breach3")}</li>
                                <li className='my-2'>{this.props.t("Terms.Breach4")}</li>
                                <li className='my-2'>{this.props.t("Terms.Breach5")}</li>
                            </ol>
                        </div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Breach6")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Indemnity")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Indemnity1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Trademark Complaint")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Trademark Complaint1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Copyright Complaint")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Copyright Complaint1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Trademark, Copyright, and Restriction")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Trademark, Copyright, and Restriction1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Limitation of Liability")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Limitation of Liability1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Applicable Law")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Applicable Law1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Jurisdictional Issues / Sale in India Only")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Jurisdictional Issues / Sale in India Only1")}</div>
                        <div className='font-semibold mb-1'>{this.props.t("Terms.Seller Declarations")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Seller Declarations1")}</div>
                        <div className='border overflow-x-scroll custom-scrollbar-horizontal2 m-4'>
                            <table className='w-full text-sm text-left text-gray-500'>
                                <thead className='text-sm whitespace-nowrap text-gray-700 uppercase bg-gray-50'>
                                    <tr>
                                        <th className='px-6 py-2 md:py-3 w-[10%]'>
                                            {this.props.t("Terms.Sellertable1")}
                                        </th>
                                        <th className='px-6 py-2 md:py-3 w-[30%]'>
                                            {this.props.t("Terms.Sellertable2")}
                                        </th>
                                        <th className='px-6 py-2 md:py-3 w-[60%]'>
                                            {this.props.t("Terms.Sellertable3")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            1
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable4")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable5")}</div>
                                            <div>{this.props.t("Terms.Sellertable6")}</div>
                                            <div>{this.props.t("Terms.Sellertable7")}</div>
                                            <div>{this.props.t("Terms.Sellertable8")}</div>
                                            <div>{this.props.t("Terms.Sellertable9")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            2
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable10")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable11")}</div>
                                            <div>{this.props.t("Terms.Sellertable12")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            3
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable13")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable14")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            4
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable15")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable16")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            5
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable17")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable18")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            6
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable19")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable20")}</div>
                                            <div>{this.props.t("Terms.Sellertable21")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            7
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable22")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable23")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            8
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable24")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable25")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            9
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable26")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable27")}</div>
                                            <div>{this.props.t("Terms.Sellertable28")}</div>
                                            <div>{this.props.t("Terms.Sellertable29")}</div>
                                            <div>{this.props.t("Terms.Sellertable30")}</div>
                                            <div>{this.props.t("Terms.Sellertable31")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            10
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable32")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable33")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            11
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable34")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable35")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            12
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable36")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable37")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            13
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable38")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <div>{this.props.t("Terms.Sellertable39")}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='px-6 py-2 md:py-4 w-[10%] align-text-top'>
                                            14
                                        </th>
                                        <td className='px-6 py-2 md:py-4 w-[30%] align-text-top'>
                                            <div>{this.props.t("Terms.Sellertable40")}</div>
                                        </td>
                                        <td className='px-6 py-2 md:py-4 w-[60%]'>
                                            <ol className='list-decimal ml-4 text-sm'>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable41")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable42")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable43")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable44")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable45")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable46")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable47")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable48")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable49")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable50")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable51")}</li>
                                                <li className='my-2'>{this.props.t("Terms.Sellertable52")}</li>
                                            </ol>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.Sellertable53")}</div>
                        <div className='my-2'>
                            <ol className='list-decimal ml-4 text-sm'>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Legal Metrology Regulations")}</div><div className='my-1'>{this.props.t("Terms.Legal Metrology Regulations1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Consumer Protection")}</div><div className='my-1'>{this.props.t("Terms.Consumer Protection1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Financial Regulations")}</div><div className='my-1'>{this.props.t("Terms.Financial Regulations1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.OFAC Regulations and Sanctions")}</div><div className='my-1'>{this.props.t("Terms.OFAC Regulations and Sanctions1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Selling11")}</div><div className='my-1'>{this.props.t("Terms.Selling12")}</div></div></li>
                            </ol>
                        </div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.1")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.2")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.3")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.4")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Terms.5")}</div>
                        <div className='my-2 text-sm'>
                            <div className='flex items-center'><div className='font-semibold whitespace-nowrap'>{this.props.t("Terms.Contact Us")}</div><div className='my-1 ml-1'>{this.props.t("Terms.Contact Us1")}</div></div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}
export default withTranslation()(Terms);