import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <div className='w-full bg-white py-2 border-t-2 border-[#041737]'>
                <div className='flex flex-row justify-between px-5 items-center max-w-[900px] m-auto text-xs md:text-sm flex-wrap'>
                    <Link to={'/terms-condition'}><div className='cursor-pointer text-[#5aa3b0]'>{this.props.t("Footer.Terms and Conditions")}</div></Link>
                    <Link to={'/terms-condition'}><div className='cursor-pointer text-[#5aa3b0]'>{this.props.t("Footer.Operating Agreement")}</div></Link>
                    <Link to={'/policy'}><div className='cursor-pointer text-[#5aa3b0]'>{this.props.t("Footer.Privacy Policy")}</div></Link>
                    <div>{this.props.t("@")}</div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Footer);