import React, { Component } from 'react'
import logo from '../Assets/logo.png'
import { Link, Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ThreeDots } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { getAgentInfo, logout, setLogin } from "../actions";
import { withTranslation } from 'react-i18next';

class Signin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAdded: false,
            email: "",
            otp: '',
            password: "",
            disableLoginWithPasswordButton: false,
            disableLoginWithOTPButton: false,
            disableSubmitButton: false,
            loginWithPasswordButtonDisbled: false,
            submitButtonDisbled: false
        }
    }
    componentDidUpdate() {
        if (this.props.agent_info && !this.state.loggedIn) {
            this.setState({ loggedIn: true })
        }
    }
    render() {
        const login = (type) => {
            if (this.state.email === "") {
                toast.error("Invalid Email !");
            }
            else if (this.state.password === "") {
                toast.error("Please enter the password !");
            }
            else {
                this.setState({ loginWithPasswordButtonDisbled: true });
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 401 || result.code === 407) {
                            this.props.logoutDispatch();

                        }
                        else {
                            if (result.status === "Success" || result.status === "success") {
                                this.setState({ emailAdded: true, loginWithPasswordButtonDisbled: false })
                                toast.success('Login Successfully')
                            }
                            else {
                                this.setState({ loginWithPasswordButtonDisbled: false })
                                toast.error(result.msg)
                            }
                        }
                    })
                    .catch((error) => {
                        this.setState({ loginWithPasswordButtonDisbled: false });
                        toast.error("Server Error")
                    });
            }

        }

        const reset = () => {
            this.setState({
                emailAdded: false,
                email: "",
                password: "",
                disableLoginWithPasswordButton: false,
                disableLoginWithOTPButton: false,
                disableSubmitButton: false,
                loggedIn: false
            })
        }
        const submit = () => {
            if (this.state.otp === "") {
                toast.error("Invalid OTP !");
            }

            else {
                this.setState({ submitButtonDisbled: true })
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    email: this.state.email,
                    OTP: this.state.otp
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_API_URL}/login_Otp`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 401 || result.code === 407) {
                            this.props.logoutDispatch();

                        }
                        else {
                            if (result.status === "Success" || result.status === "success") {
                                toast.success("Signed in successfullly !")
                                this.props.loginDispatch(result.token);
                                this.props.agentInfoDispatch(result.token);
                                this.setState({ submitButtonDisbled: false });
                                const dukkandaar_seller_hub = {
                                    token: result.token
                                }
                                window.localStorage.setItem("dukkandaar_seller_hub", JSON.stringify(dukkandaar_seller_hub));
                            } else {
                                toast.error(result.msg)
                                this.setState({ submitButtonDisbled: false })
                            }
                        }
                    })
                    .catch((error) => {
                        this.setState({ submitButtonDisbled: false });
                        toast.error("Server Error")
                    });
            }
        }
        const resendOTP = () => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "email": this.state.email
            });
            console.log(this.state.email)
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/resend_Otp`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === "Success" || result.status === "success") {
                        toast.success(result.msg);
                    }
                    else {
                        toast.error(result.msg);
                    }
                })
                .catch(error => console.log('error', error));
        }
        return (
            <div className='w-full max-w-[500px] px-5 py-4 m-auto'>
                {
                    this.state.loggedIn && <Navigate to={'/dashboard'} />
                }
                <img src={logo} className='w-[150px] m-auto' alt="" />
                <div className='w-full mt-4 py-4 px-8 rounded-2xl border-2 border-black'>
                    <div className='font-medium text-2xl'>{this.props.t("Signin.Sign In")}</div>
                    {
                        !this.state.emailAdded ?
                            <>
                                <div className='my-4'>
                                    <div className='text-xs text-gray-500 font-medium'>{this.props.t("Signin.Email Id")}</div>
                                    <input type="email" name='email' id='email' className='border border-black rounded-md w-full p-2 text-sm mt-1' placeholder='Enter registered Email' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                </div>
                                <div className='my-4'>
                                    <div className='text-xs text-gray-500 font-medium'>{this.props.t("Signin.Password")}</div>
                                    <input type="password" name='password' id='password' className='border border-black rounded-md w-full p-2 text-sm mt-1' placeholder='Enter Your Password' value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                </div>
                                <Link to={'/reset-password'} className='text-[#5aa3b0] text-right float-right mb-2 font-semibold text-xs cursor-pointer'>{this.props.t("Signin.Forget Password")}?</Link>
                                <div className='my-4'>
                                    <button
                                        type="submit"
                                        className={`bg-[#FFD814]  w-full text-center py-2 text-sm font-semibold rounded-full shadow-md ${this.state.loginWithPasswordButtonDisbled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                                        onClick={(e) => login(e)}
                                        disabled={this.state.loginWithPasswordButtonDisbled}
                                    >
                                        <div className={`${!this.state.loginWithPasswordButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            {this.props.t("Send OTP")}
                                        </div>
                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.loginWithPasswordButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            <ThreeDots
                                                height="32"
                                                width="30"
                                                radius="9"
                                                color="#000"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    </button>
                                </div>
                                {/* <div className='my-4 py-2'>
                                    <div className='relative w-full h-[0.5px] bg-black'>
                                        <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] px-2 bg-white'>or</div>
                                    </div>
                                </div>
                                <div className='my-4'>
                                    <button className='bg-[#FFD814] w-full text-center py-2 text-sm font-semibold rounded-full shadow-md' onClick={() => login("otp")}>Login with OTP</button>
                                </div> */}
                            </> :
                            <>
                                <div className='my-4'>
                                    <div className='flex justify-between items-center flex-row'>
                                        <div className='flex flex-row justify-start items-center'>
                                            <div className='text-xs text-gray-500 font-semibold'>{this.props.t("Signin.Email")}: {this.state.email}</div>
                                            <div className='text-[#5aa3b0] font-semibold text-xs cursor-pointer ml-3' onClick={() => reset()}>{this.props.t("Signin.Change")}</div>
                                        </div>
                                    </div>

                                    <input type="password" name="otp" id="otp" maxLength="6" className='border border-black rounded-md w-full p-2 text-sm mt-1' placeholder='Enter OTP Received' value={this.state.otp} onChange={(e) => {
                                        const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                        this.setState({ otp: numericValue });
                                    }} />
                                    <div className='my-1 flex flex-row justify-start items-center'>
                                        <div className='text-xs font-semibold text-[#5aa3b0] cursor-pointer' onClick={() => resendOTP()}>{this.props.t("VerifyOTPForm.Resend OTP")}</div>
                                    </div>
                                </div>
                                <div className='my-4'>
                                    <button
                                        type="submit"
                                        className={`bg-[#FFD814]  w-full text-center py-2 text-sm font-semibold rounded-full shadow-md ${this.state.submitButtonDisbled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                                        onClick={() => submit()}
                                        disabled={this.state.submitButtonDisbled}
                                    >
                                        <div className={`${!this.state.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            {this.props.t("Submit")}
                                        </div>
                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            <ThreeDots
                                                height="32"
                                                width="30"
                                                radius="9"
                                                color="#000"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    </button>
                                </div>
                            </>
                    }
                    <div className='my-4'>
                        <div className='text-gray-500 text-sm'>{this.props.t("Signin.By continuing")} <span className='text-blue-800 cursor-pointer'>{this.props.t("Signin.Conditions of Use")}</span> {this.props.t("Signin.and")} <span className='text-blue-800 cursor-pointer'>{this.props.t("Signin.Privacy Notice")}</span>.</div>
                    </div>
                </div>
                {/* <div className='my-4 pt-4 pb-3'>
                    <div className='relative w-full h-[0.5px] bg-gray-500'>
                        <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] px-2 bg-white text-gray-500'>New to Dukkandaar ?</div>
                    </div>
                </div> */}
                <div className='text-sm pt-4 text-gray-700 text-center'>
                    {this.props.t("@")}
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const loginDispatch = (token) => {
        dispatch(setLogin(token));
    };
    const agentInfoDispatch = (token) => {
        dispatch(getAgentInfo(token));
    };
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch,
        loginDispatch,
        agentInfoDispatch
    };
};
const mapStateToProps = (state) => {
    return {
        token: state.token,
        agent_info: state.agent_info
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Signin));