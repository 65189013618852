import React, { Component } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';
import { Navigate } from 'react-router-dom';
import GSTForm from '../Components/Onboarding/GSTForm';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import BankingForm from '../Components/Onboarding/BankingForm';
import BillingForm from '../Components/Onboarding/BillingForm';
import ShippingForm from '../Components/Onboarding/ShippingForm';
import ReturnForm from '../Components/Onboarding/ReturnForm';
import { getSellers, logout } from '../actions';
import { withTranslation } from 'react-i18next';
import AccessDenied from '../Components/ProtectRoutes/AccessDenied';
const withRouter = WrappedComponent => props => {
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            params={params}
        />
    );
};

class Onboarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onboarding_state: "GST/MSME", // 'GST/MSME', 'Banking', 'Billing', 'Shipping', 'Return'
            seller_id: null,
            navigateTo: null,
            seller_data: null,
            onboarding_number: 1,
            submitButtonDisbled: false
        }
    }
    componentDidMount() {
        if (!this.state.seller_data && this.props.token) {
            const { seller_id } = this.props.params;
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/get_single_sellers/${seller_id}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                    if (res.code === 401 || res.code === 407) {
                        this.props.logoutDispatch();

                    }
                    else {
                        if (res.status === "Success" || res.status === "success") {
                            this.setState({ seller_data: res.seller_data, seller_id: seller_id });
                            let seller_data = res.seller_data;
                            if (seller_data.gst_status || seller_data.gst_tax_status || seller_data.msme_status) {
                                if (seller_data.bank_details_status) {
                                    if (seller_data.billing_address_status) {
                                        if (seller_data.shipping_address_status) {
                                            if (seller_data.return_address_status) {
                                                this.props.getSellersDispatch({ token: this.props.token, page: 1 });
                                                this.setState({ navigateTo: `/seller-details/${seller_id}` });
                                            }
                                            else {
                                                this.setState({ onboarding_state: "Return", onboarding_number: 5 });
                                            }
                                        }
                                        else {
                                            this.setState({ onboarding_state: "Shipping", onboarding_number: 4 });
                                        }
                                    }
                                    else {
                                        this.setState({ onboarding_state: "Billing", onboarding_number: 3 });
                                    }
                                }
                                else {
                                    this.setState({ onboarding_state: "Banking", onboarding_number: 2 });
                                }
                            }
                            else {
                                this.setState({ onboarding_state: "GST/MSME", onboarding_number: 1 });
                            }
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.onboarding_state !== this.state.onboarding_state) {
            this.props.getSellersDispatch({ token: this.props.token, page: 1 });
            const { seller_id } = this.props.params;
            if (seller_id) {
                this.setState({ seller_id });
                var myHeaders = new Headers();
                myHeaders.append("token", this.props.token);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_API_URL}/get_single_sellers/${seller_id}`, requestOptions)
                    .then(response => response.json())
                    .then(res => {
                        if (res.code === 401 || res.code === 407) {
                            this.props.logoutDispatch();
                            toast.error(res.msg);
                        }
                        else {
                            console.log(res);
                            if (res.status === "Success" || res.status === "success") {
                                this.setState({ seller_data: res.seller_data });
                            }
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        }
        if (!this.state.seller_data && this.props.token) {
            const { seller_id } = this.props.params;
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/get_single_sellers/${seller_id}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                    if (res.code === 401 || res.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (res.status === "Success" || res.status === "success") {
                            this.setState({ seller_data: res.seller_data, seller_id: seller_id });
                            let seller_data = res.seller_data;
                            if (seller_data.gst_status || seller_data.gst_tax_status || seller_data.msme_status) {
                                if (seller_data.bank_details_status) {
                                    if (seller_data.billing_address_status) {
                                        if (seller_data.shipping_address_status) {
                                            if (seller_data.return_address_status) {
                                                this.props.getSellersDispatch({ token: this.props.token, page: 1 });
                                                this.setState({ navigateTo: `/seller-details/${seller_id}` });
                                            }
                                            else {
                                                this.setState({ onboarding_state: "Return", onboarding_number: 5 });
                                            }
                                        }
                                        else {
                                            this.setState({ onboarding_state: "Shipping", onboarding_number: 4 });
                                        }
                                    }
                                    else {
                                        this.setState({ onboarding_state: "Billing", onboarding_number: 3 });
                                    }
                                }
                                else {
                                    this.setState({ onboarding_state: "Banking", onboarding_number: 2 });
                                }
                            }
                            else {
                                this.setState({ onboarding_state: "GST/MSME", onboarding_number: 1 });
                            }
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    render() {
        const verifyGST = (data) => {
            this.setState({ submitButtonDisbled: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");
            let id = this.state.seller_id;
            var raw = JSON.stringify(data);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/verify_gst_details/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            // console.log(result)
                            toast.success('Successfully Verified')
                            this.setState({ onboarding_state: "Banking", onboarding_number: 2 })
                            this.setState({ submitButtonDisbled: false });
                        }
                        else {
                            toast.error(result.msg)
                            this.setState({ submitButtonDisbled: false });
                        }
                    }
                })
                .catch((error) => {
                    toast.error("Server Error")
                });
        }
        const verifyBankDetails = (data) => {
            this.setState({ submitButtonDisbled: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");
            let id = this.state.seller_id;
            var raw = JSON.stringify(data);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/add_bank_details/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success('Successfully Added')
                            // console.log(result)
                            this.setState({ onboarding_state: "Billing", onboarding_number: 3, submitButtonDisbled: false })
                        }
                        else {
                            toast.error(result.msg)
                            this.setState({ submitButtonDisbled: false });
                        }
                    }
                })
                .catch((error) => {
                    toast.error("Server Error")
                });
        }
        const verifyBillingDetails = (data) => {
            this.setState({ submitButtonDisbled: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");
            let id = this.state.seller_id;
            var raw = JSON.stringify(data);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/add_billing_address/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success(result.msg)
                            // console.log(result)
                            this.setState({ onboarding_state: "Shipping", onboarding_number: 4 })
                        }
                        else {
                            toast.error(result.msg)
                        }
                        this.setState({ submitButtonDisbled: false });
                    }
                })
                .catch((error) => {
                    toast.error("Server Error")
                });
        }

        const verifyShippingDetails = (data) => {
            this.setState({ submitButtonDisbled: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");
            let id = this.state.seller_id;
            var raw = JSON.stringify(data);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/add_shipping_address/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success(result.msg)
                            // console.log(result)
                            this.setState({ onboarding_state: "Return", onboarding_number: 5 })
                        }
                        else {
                            toast.error(result.msg)
                        }
                        this.setState({ submitButtonDisbled: false });
                    }
                })
                .catch((error) => {
                    toast.error("Server Error")
                });
        }
        const verifyReturnDetails = (data) => {
            this.setState({ submitButtonDisbled: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");
            let id = this.state.seller_id;
            var raw = JSON.stringify(data);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/add_return_address/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success(result.msg)
                            this.props.getSellersDispatch({ token: this.props.token, page: 1 });
                            this.setState({ navigateTo: `/seller-details/${this.state.seller_id}` });
                        }
                        else {
                            toast.error(result.msg)
                        }
                        this.setState({ submitButtonDisbled: false });
                    }
                })
                .catch((error) => {
                    toast.error("Server Error")
                });
        }
        const changeOnboardingState = (status, number) => {
            this.setState({ onboarding_state: status, onboarding_number: number, submitButtonDisbled: false });
        }
        let breadcrumbs = [
            {
                name: 'Sellers',
                link: '/sellers'
            },
            {
                name: 'Add New Seller',
                link: '/add-seller'
            },
        ]
        return (
            <>
                {this.props.token ? (
                    <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                        {
                            this.state.navigateTo && <Navigate to={this.state.navigateTo} />
                        }
                        <div className='w-fit h-fit z-50 fixed'>
                            <Sidebar />
                        </div>
                        <div className='w-full md:w-[calc(100%-250px)] webkit-center ml-auto min-h-screen relative overflow-x-hidden'>
                            <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                                <Navbar breadcrumbs={breadcrumbs} />
                            </div>
                            {/* <div className='flex w-full justify-center items-center sm:gap-3 gap-1 px-3 mt-[80px] md:mt-[50px]'>
                        <div className={`text-center font-semibold ${this.state.onboarding_state === "GST/MSME" ? 'bg-[#fde465]' : 'bg-white'} text-[10px] sm:text-xs rounded-md w-full flex-shrink py-2 sm:py-3 shadow-md`}>
                            GST/ MSME Details
                        </div>
                        <div className={`text-center font-semibold ${this.state.onboarding_state === "Banking" ? 'bg-[#fde465]' : 'bg-white'} text-[10px] sm:text-xs rounded-md w-full flex-shrink py-2 sm:py-3 shadow-md`}>
                            Banking Details
                        </div>
                        <div className={`text-center font-semibold ${this.state.onboarding_state === "Billing" ? 'bg-[#fde465]' : 'bg-white'} text-[10px] sm:text-xs rounded-md w-full flex-shrink py-2 sm:py-3 shadow-md`}>
                            Billing Address
                        </div>
                        <div className={`text-center font-semibold ${this.state.onboarding_state === "Shipping" ? 'bg-[#fde465]' : 'bg-white'} text-[10px] sm:text-xs rounded-md w-full flex-shrink py-2 sm:py-3 shadow-md`}>
                            Shipping Address
                        </div>
                        <div className={`text-center font-semibold ${this.state.onboarding_state === "Return" ? 'bg-[#fde465]' : 'bg-white'} text-[10px] sm:text-xs rounded-md w-full flex-shrink py-2 sm:py-3 shadow-md`}>
                            Return Address
                        </div>
                    </div> */}
                            <div className='mt-[80px] md:mt-[50px]'>
                                <div className='flex justify-center items-center flex-row w-[90%] m-auto max-w-[800px] px-3 mb-[50px]'>
                                    <div className='relative'>
                                        <div className={`w-[30px] h-[30px] rounded-full ${this.state.onboarding_number >= 1 ? 'bg-yellow-300' : 'bg-white'} flex justify-center items-center text-sm flex-shrink-0`}>1</div>
                                        <div className='text-[10px] whitespace-nowrap font-semibold absolute top-[115%] left-[50%] translate-x-[-50%] text-center'>{this.props.t("Onboarding.GST/MSME")} <br /> {this.props.t("Onboarding.Details")}</div>
                                    </div>
                                    <div className='h-[2px] w-full flex-shrink bg-white'>
                                        <div className={`h-full bg-yellow-300 duration-500 mr-auto ${this.state.onboarding_number > 1 ? 'w-[100%]' : 'w-0'}`}></div>
                                    </div>
                                    <div className='relative'>
                                        <div className={`w-[30px] h-[30px] rounded-full ${this.state.onboarding_number >= 2 ? 'bg-yellow-300' : 'bg-white'} flex justify-center items-center text-sm flex-shrink-0`}>2</div>
                                        <div className='text-[10px] whitespace-nowrap font-semibold absolute top-[115%] left-[50%] translate-x-[-50%] text-center'>{this.props.t("Onboarding.Banking")} <br /> {this.props.t("Onboarding.Details")}</div>
                                    </div>
                                    <div className='h-[2px] w-full flex-shrink bg-white'>
                                        <div className={`h-full bg-yellow-300 duration-500 mr-auto ${this.state.onboarding_number > 2 ? 'w-[100%]' : 'w-0'}`}></div>
                                    </div>
                                    <div className='relative'>
                                        <div className={`w-[30px] h-[30px] rounded-full ${this.state.onboarding_number >= 3 ? 'bg-yellow-300' : 'bg-white'} flex justify-center items-center text-sm flex-shrink-0`}>3</div>
                                        <div className='text-[10px] whitespace-nowrap font-semibold absolute top-[115%] left-[50%] translate-x-[-50%] text-center'>{this.props.t("Onboarding.Billing")} <br /> {this.props.t("Onboarding.Address")}</div>
                                    </div>
                                    <div className='h-[2px] w-full flex-shrink bg-white'>
                                        <div className={`h-full bg-yellow-300 duration-500 mr-auto ${this.state.onboarding_number > 3 ? 'w-[100%]' : 'w-0'}`}></div>
                                    </div>
                                    <div className='relative'>
                                        <div className={`w-[30px] h-[30px] rounded-full ${this.state.onboarding_number >= 4 ? 'bg-yellow-300' : 'bg-white'} flex justify-center items-center text-sm flex-shrink-0`}>4</div>
                                        <div className='text-[10px] whitespace-nowrap font-semibold absolute top-[115%] left-[50%] translate-x-[-50%] text-center'>{this.props.t("Onboarding.Shipping")} <br /> {this.props.t("Onboarding.Address")}</div>
                                    </div>
                                    <div className='h-[2px] w-full flex-shrink bg-white'>
                                        <div className={`h-full bg-yellow-300 duration-500 mr-auto ${this.state.onboarding_number > 4 ? 'w-[100%]' : 'w-0'}`}></div>
                                    </div>
                                    <div className='relative'>
                                        <div className={`w-[30px] h-[30px] rounded-full ${this.state.onboarding_number >= 5 ? 'bg-yellow-300' : 'bg-white'} flex justify-center items-center text-sm flex-shrink-0`}>5</div>
                                        <div className='text-[10px] whitespace-nowrap font-semibold absolute top-[115%] left-[50%] translate-x-[-50%] text-center'>{this.props.t("Onboarding.Return")} <br /> {this.props.t("Onboarding.Address")}</div>
                                    </div>
                                </div>
                                <div className='px-3 max-w-[500px] mt-3 mb-[50px]'>
                                    {
                                        this.state.onboarding_state === "GST/MSME" &&
                                        <GSTForm verifyGST={verifyGST} submitButtonDisbled={this.state.submitButtonDisbled} />
                                    }
                                    {
                                        this.state.onboarding_state === "Banking" &&
                                        <BankingForm verifyBankDetails={verifyBankDetails} submitButtonDisbled={this.state.submitButtonDisbled} />
                                    }
                                    {
                                        this.state.onboarding_state === "Billing" &&
                                        <BillingForm verifyBillingDetails={verifyBillingDetails} submitButtonDisbled={this.state.submitButtonDisbled} />
                                    }
                                    {
                                        this.state.onboarding_state === "Shipping" &&
                                        <ShippingForm verifyShippingDetails={verifyShippingDetails} seller_data={this.state.seller_data} params={this.state.seller_id} token={this.props.token} changeOnboardingState={changeOnboardingState} submitButtonDisbled={this.state.submitButtonDisbled} />
                                    }
                                    {
                                        this.state.onboarding_state === "Return" &&
                                        <ReturnForm verifyReturnDetails={verifyReturnDetails} seller_data={this.state.seller_data} params={this.state.seller_id} token={this.props.token} submitButtonDisbled={this.state.submitButtonDisbled} />
                                    }
                                </div>
                            </div>
                            <div className='w-full mt-3 absolute bottom-0'>
                                <Footer />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const getSellersDispatch = (data) => {
        dispatch(getSellers(data.token, data.page, null, null, null, null, null));
    }
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch,
        getSellersDispatch
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Onboarding)));