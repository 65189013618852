import React, { Component } from 'react'
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { logout } from '../../actions';

class VerifyOTPForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpd1: "",
            otpd2: "",
            otpd3: "",
            otpd4: "",
            otpd5: "",
            otpd6: ""
        }
        this.otp1ref = React.createRef();
        this.otp2ref = React.createRef();
        this.otp3ref = React.createRef();
        this.otp4ref = React.createRef();
        this.otp5ref = React.createRef();
        this.otp6ref = React.createRef();
    }
    render() {
        const { seller_data, verifyButtonDisabled } = this.props;
        const verifyOTP = () => {
            let otp = `${this.state.otpd1}${this.state.otpd2}${this.state.otpd3}${this.state.otpd4}${this.state.otpd5}${this.state.otpd6}`;
            console.log(otp);
            if (otp.length !== 6) {
                toast.error("Incorrect OTP!")
            }
            else {
                otp = (Number)(otp);
                this.props.verifySeller(otp);
            }
        }
        const resendOTP = () => {
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "number": seller_data.number
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/signup_send_otp`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success(result.msg);
                        }
                        else {
                            toast.error(result.msg);
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
        return (
            <div className='w-full p-3 bg-white rounded-md'>
                <div className='text-lg font-semibold'>{this.props.t("VerifyOTPForm.Verify OTP")}</div>
                <div className='text-xs text-gray-400 mb-2'>{this.props.t("VerifyOTPForm.Verify OTP1")}.</div>
                <div className='text-xs my-2'>{this.props.t("VerifyOTPForm.Shop Name")} : {seller_data.shop_name}</div>
                <div className='text-xs my-2'>{this.props.t("VerifyOTPForm.Seller Name")} : {seller_data.seller_name}</div>
                <div className='text-xs mb-6'>{this.props.t("VerifyOTPForm.Mobile Number")} : {seller_data.number}</div>

                <div className='my-6 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black cursor-pointer'>{this.props.t("VerifyOTPForm.OTP Recieved")}</div>
                    <div className='w-full max-w-[320px] flex justify-start items-center gap-3 mt-2'>
                        <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp1ref} value={this.state.otpd1} onChange={(e) => {
                            if (e.target.value.length === 1) {
                                this.setState({ otpd1: e.target.value });
                                this.otp2ref.current.focus();
                            }
                            if (e.target.value.length === 0) {
                                this.setState({ otpd1: e.target.value });
                            }
                        }} />
                        <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp2ref} value={this.state.otpd2} onChange={(e) => {
                            if (e.target.value.length === 1) {
                                this.setState({ otpd2: e.target.value });
                                this.otp3ref.current.focus();
                            }
                            if (e.target.value.length === 0) {
                                this.setState({ otpd2: e.target.value });
                                this.otp1ref.current.focus();
                            }
                        }} />
                        <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp3ref} value={this.state.otpd3} onChange={(e) => {
                            if (e.target.value.length === 1) {
                                this.setState({ otpd3: e.target.value });
                                this.otp4ref.current.focus();
                            }
                            if (e.target.value.length === 0) {
                                this.setState({ otpd3: e.target.value });
                                this.otp2ref.current.focus();
                            }
                        }} />
                        <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp4ref} value={this.state.otpd4} onChange={(e) => {
                            if (e.target.value.length === 1) {
                                this.setState({ otpd4: e.target.value });
                                this.otp5ref.current.focus();
                            }
                            if (e.target.value.length === 0) {
                                this.setState({ otpd4: e.target.value });
                                this.otp3ref.current.focus();
                            }
                        }} />
                        <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp5ref} value={this.state.otpd5} onChange={(e) => {
                            if (e.target.value.length === 1) {
                                this.setState({ otpd5: e.target.value });
                                this.otp6ref.current.focus();
                            }
                            if (e.target.value.length === 0) {
                                this.setState({ otpd5: e.target.value });
                                this.otp4ref.current.focus();
                            }
                        }} />
                        <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp6ref} value={this.state.otpd6} onChange={(e) => {
                            if (e.target.value.length === 1) {
                                this.setState({ otpd6: e.target.value });
                            }
                            if (e.target.value.length === 0) {
                                this.setState({ otpd6: e.target.value });
                                this.otp5ref.current.focus();
                            }
                        }} />
                    </div>
                </div>
                <div className='my-6 flex flex-row justify-start items-center'>
                    <div className='text-xs font-semibold text-[#5aa3b0] cursor-pointer' onClick={() => resendOTP()}>{this.props.t("VerifyOTPForm.Resend OTP")}</div>
                </div>
                <div className='my-6'>
                    <button
                        type="submit"
                        className={`bg-[#FFD814]  text-center py-1 px-8 text-sm font-semibold rounded-full shadow-md ${verifyButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                        onClick={() => verifyOTP()}
                        disabled={verifyButtonDisabled}
                    >
                        <div className={`${!verifyButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            {this.props.t("VerifyOTPForm.Verify OTP")}
                        </div>
                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${verifyButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            <ThreeDots
                                height="32"
                                width="30"
                                radius="9"
                                color="#000"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    </button>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VerifyOTPForm));