import React, { Component } from 'react'
import logo from '../../Assets/logo-light.png'
import { BiSolidDashboard } from 'react-icons/bi'
import { BsPersonFillAdd } from 'react-icons/bs'
import { MdPeopleAlt, MdOutlineSettingsSuggest, MdLogout } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../actions'
import { FaTimes } from 'react-icons/fa'
import { withTranslation } from 'react-i18next'

const withRouter = WrappedComponent => props => {
    const location = useLocation();
    return (
        <WrappedComponent
            {...props}
            location={location}
        />
    );
};

class TopbarDocs extends Component {
    render() {
        const { pathname } = this.props.location;
        return (
            <div className="w-[250px] h-screen bg-[#041737] py-4 px-8 overflow-auto custom-scrollbar-sidebar text-white absolute top-0 left-0 transform md:translate-x-0 transition-transform duration-300 ease-in-out">
                <div className='flex justify-between content-center items-center'>
                    <div><img src={logo} className='w-[100px] m-auto mb-10' alt="" /></div>
                    <div className="md:hidden cursor-pointer" onClick={() => this.props.hide()}><FaTimes size={20} /></div>
                </div>
                <div>

                    <div className='mb-1'>
                        <Link to={'/dashboard'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/dashboard" && 'bg-[#3b62ad] text-white '} duration-300 ease-in-out text-gray-400`}>
                            <BiSolidDashboard size={20} />
                            <div className={`w-full flex-shrink ml-3 ${pathname === "/dashboard" && 'text-white'} font-medium text-sm`}>{this.props.t("docs.Main")}</div>
                        </Link>
                    </div>
                    <div className='mb-1'>
                        <Link to={'/docs'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/docs" && 'bg-[#3b62ad] text-white '} duration-300 ease-in-out text-gray-400`}>
                            <BiSolidDashboard size={20} />
                            <div className={`w-full flex-shrink ml-3 ${pathname === "/docs" && 'text-white'} font-medium text-sm`}>{this.props.t("docs.Introduction")}</div>
                        </Link>
                    </div>
                    <div className='text-xs text-[#446ea9] mt-4 mb-1'>{this.props.t("Sidebar.Dashboard")}</div>
                    <div className='mb-1'>
                        <Link to={'/docs/Dash'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/docs/Dash" && 'bg-[#3b62ad] text-white'} duration-300 ease-in-out text-gray-400`}>
                            <BiSolidDashboard size={20} />
                            <div className={`w-full flex-shrink ml-3 ${pathname === "/docs/Dash" && 'text-white'} font-medium text-sm`}>{this.props.t("Sidebar.Dashboard")}</div>
                        </Link>
                    </div>
                    <div className='text-xs text-[#446ea9] mt-4 mb-1'>{this.props.t("Sidebar.Seller Management")}</div>
                    <div className='mb-1'>
                        <Link to={'/docs/ManageSeller'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/docs/ManageSeller" && 'bg-[#3b62ad] text-white'} duration-300 ease-in-out text-gray-400`}>
                            <MdPeopleAlt size={20} />
                            <div className='w-full flex-shrink ml-3 font-medium text-sm'>{this.props.t("Sidebar.Manage Sellers")}</div>
                        </Link>
                    </div>
                    <div className='mb-1'>
                        <Link to={'/docs/addseller'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/docs/addseller" && 'bg-[#3b62ad] text-white'} duration-300 ease-in-out text-gray-400`}>
                            <BsPersonFillAdd size={20} />
                            <div className='w-full flex-shrink ml-3 font-medium text-sm'>{this.props.t("Sidebar.Add Seller")}</div>
                        </Link>
                    </div>
                    <div className='text-xs text-[#446ea9] mt-4 mb-1'>{this.props.t("Sidebar.General")}</div>
                    <div className='mb-1'>
                        <Link to={'/docs/settings'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/docs/settings" && 'bg-[#3b62ad] text-white'} duration-300 ease-in-out text-gray-400`}>
                            <MdOutlineSettingsSuggest size={20} />
                            <div className='w-full flex-shrink ml-3 font-medium text-sm'>{this.props.t("Sidebar.Settings")}</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};

const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(TopbarDocs)));