import React, { Component } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from '../Components/Navbar'
import Chart from "chart.js/auto";
import { Line, Doughnut } from "react-chartjs-2";
import DashboardCard from '../Components/DashboardCard'
import Table from '../Components/Table/Table';
import Footer from '../Components/Footer';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AccessDenied from '../Components/ProtectRoutes/AccessDenied';
import toast from 'react-hot-toast';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gradient: null,
            label: '',
            revenue: '',
            total_orders: '',
            data: []
        }
    }
    componentDidMount() {
        var canvas = document.getElementById('canvas');
        if (canvas) {
            var ctx = canvas.getContext("2d");
            var gradient = ctx.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(0, '#45d39f80');
            gradient.addColorStop(1, '#45d39f00');
            this.setState({ gradient: gradient });
        }
        if (this.props.token && this.state.label === '' && this.state.revenue === '') {
            this.fetchDashboardData();
        }
        if (this.props.token && this.state.data.length === 0) {
            this.fetchTopSellers();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.token && this.state.label === '' && this.state.revenue === '') {
            this.fetchDashboardData();
        }
        if (this.props.token && this.state.data.length === 0) {
            this.fetchTopSellers();
        }
    }
    fetchDashboardData = () => {
        var myHeaders = new Headers();
        myHeaders.append("token", this.props.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_URL}/graphdata`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 401 || result.code === 407) {
                    this.props.logoutDispatch();
                }
                else {
                    if (result.status === "Success" || result.status === "success") {
                        const labels = result.data.map(item => item.label);
                        const revenues = result.data.map(item => parseFloat(item.revenue));
                        this.setState({ label: labels, revenue: revenues });
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
    fetchTopSellers = () => {
        var myHeaders = new Headers();
        myHeaders.append("token", this.props.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_URL}/analytics`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 401 || result.code === 407) {
                    this.props.logoutDispatch();
                }
                else {
                    if (result.status === "Success" || result.status === "success") {
                        this.setState({
                            data: [result]
                        });
                    }
                }
            })
            .catch(error => console.log('error', error));

    }
    render() {
        let breadcrumbs = [
            {
                name: 'Dashboard',
                link: '/dashboard'
            }
        ];
        const options = {
            plugins: {
                legend: false
            },
        }
        const lineChartData = {
            labels: this.state.label, // Use label from state
            datasets: [
                {
                    label: "Sales",
                    backgroundColor: this.state.gradient,
                    borderColor: "#45d39f",
                    data: this.state.revenue, // Use revenue from state
                    fill: true,
                    drawActiveElementsOnTop: false,
                    tension: 0.5
                },
            ],
        };
        return (<>
            {this.props.token ? (
                <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                    <div className='w-fit h-fit z-50 fixed'>
                        <Sidebar />
                    </div>
                    <div className='md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
                        <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                            <Navbar breadcrumbs={breadcrumbs} />
                        </div>
                        <div className='px-3 mt-[80px] md:mt-[50px]'>
                            <div className='flex-shrink-0 flex-grow-0 flex flex-col overflow-y-auto overflow-x-hidden'>
                                {this.state.data.map((item, index) => (
                                    <div key={index} className='w-full flex flex-row justify-start items-center gap-3 overflow-x-auto custom-scrollbar-horizontal flex-shrink-0 flex-grow-0'>
                                        {Object.entries(item).map(([key, value]) => (
                                            key !== 'status' && <DashboardCard key={key} status={this.props.t(`DashboardCard.${key}`)} number={value} />
                                        ))}
                                    </div>
                                ))}
                                <div className='my-3 h-fit w-full block sm:flex justify-center webkit-center items-stretch gap-3'>
                                    <div className='sm:w-[80%] self-center bg-white p-3 rounded-md'>
                                        <div className='text-sm font-semibold mb-2'>{this.props.t("Dashboard.12 Months Revenue")}</div>
                                        <Line id='canvas' data={lineChartData} className='w-full' options={options} />
                                    </div>
                                </div>
                                <div className='w-full rounded-md bg-white p-3'>
                                    <div className='text-lg font-semibold'>{this.props.t("Dashboard.Top Sellers")}</div>
                                    <div className='text-xs text-gray-400'>{this.props.t("Dashboard.Top Sellers1")}.</div>
                                    <div className='overflow-x-auto custom-scrollbar-horizontal pb-1 mt-3'>
                                        {
                                            this.props.seller_data && this.props.seller_data.sellers.length > 0 && <Table seller_data={this.props.seller_data} sellers={this.props.seller_data.sellers.slice(0, 5)} paginationAllowed={false} currentPage={1} totalPages={1} />
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-3'>
                            <Footer />
                        </div>
                    </div>
                </div>
            ) : (
                <div><AccessDenied /></div>
            )}
        </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        agent_info: state.agent_info,
        seller_data: state.seller_data
    };
};

export default connect(mapStateToProps, null)(withTranslation()(Dashboard));