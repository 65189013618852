import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { BsStarFill, BsStarHalf, BsArrowUp, BsArrowDown } from 'react-icons/bs'
import { Link } from 'react-router-dom';

class TableRowLight extends Component {
    render() {
        const { seller } = this.props;
        const stars = (seller.rating) * 2;
        const renderStars = (stars) => {
            if (stars % 2 === 0) {
                return (
                    <>
                        {Array.from({ length: stars / 2 }).map((_star, i) => <BsStarFill color='yellow' size={12} stroke='#000000' strokeWidth={0.5} key={i} />)}
                    </>
                )
            }
            else {
                return (
                    <>
                        {Array.from({ length: (stars - 1) / 2 }).map((_star, i) => <BsStarFill color='yellow' size={12} stroke='#000000' strokeWidth={0.5} key={i} />)}
                        <BsStarHalf color='yellow' size={12} stroke='#000000' strokeWidth={0.5} />
                    </>
                )
            }
        }
        return (
            <div to={`/seller-details/${seller.seller_id}`} className='flex justify-start items-center text-xs text-black bg-[#f2f3ff] min-w-full w-fit border border-gray-400 border-t-0'>
                <div className='border-r border-gray-400 text-center w-[60px] px-3 py-2 flex-grow-0 flex-shrink-0'>{this.props.sn}</div>
                <div className='border-r border-gray-400 px-3 py-2 w-full min-w-[60px] flex-shrink'><span className='line-clamp-1'>{this.props.seller.shop_name}</span></div>
                <div className='border-r border-gray-400 w-[200px] px-3 py-2 flex-grow-0 flex-shrink-0 text-center'><span className='line-clamp-1'>{this.props.seller.email}</span></div>
                <div className='border-r border-gray-400 px-3 py-2 w-[300px] flex-shrink'><span className='line-clamp-1'>{this.props.seller.seller_name}</span></div>
                <div className='border-r border-gray-400 w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0 text-center'><span className='line-clamp-1'>{this.props.seller.number}</span></div>
                <div className='border-r border-gray-400 w-[200px] px-3 py-2 flex-grow-0 flex-shrink-0 text-white text-center'>
                    {!this.props.seller.signup_otp_status ? <Link className='px-2 py-1 bg-blue-700 text-white rounded-md' to={`/verify-seller/${seller.seller_id}`}>{this.props.t("Table.Verify Now")}</Link>
                        : ((!this.props.seller.gst_tax_status || !this.props.seller.signup_otp_status || !this.props.seller.bank_details_status) ? <Link className='px-2 py-1 bg-red-500 rounded-md' to={`/onboarding/${seller.seller_id}`}>{this.props.t("Table.Complete Onboard")}</Link> : (!this.props.seller.billing_address_status || !this.props.seller.shipping_address_status || !this.props.seller.return_address_status) ? <div><Link className='px-2 py-1 bg-orange-500 rounded-md' to={`/onboarding/${seller.seller_id}`}>{this.props.t("Table.Add Address")}</Link><Link className='px-2 py-1 bg-green-700 text-white ml-1 rounded-md' to={`/seller-details/${seller.seller_id}`}>{this.props.t("Table.View Details")}</Link></div> : <Link className='px-2 py-1 bg-green-700 text-white rounded-md' to={`/seller-details/${seller.seller_id}`} state={{ filters: this.props.filters }} >{this.props.t("Table.View Details")}</Link>)
                    }
                </div>
                {/* <div className='border-r border-gray-400 px-3 py-2 w-full min-w-[150px] flex-shrink'><span className='line-clamp-1'>{seller.name}</span></div>
                <div className='border-r border-gray-400 w-[100px] text-center px-3 py-2 flex-grow-0 flex-shrink-0'><span className='line-clamp-1'>{seller.status}</span></div>
                <div className='border-r border-gray-400 w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0'><span className='line-clamp-1'>{seller.contact}</span></div>
                <div className='border-r border-gray-400 text-center w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0'>{seller.orders ? seller.orders : '-'}</div>
                <div className='border-r border-gray-400 text-center w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0'>{seller.revenue ? seller.revenue : '-'}</div>
                <div className={`border-r border-gray-400 text-center ${seller.growth ? (seller.growth >= 0 ? 'text-green-800' : 'text-red-600') : 'text-black'} w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0 flex justify-center items-center gap-1`}>{seller.growth ? (seller.growth >= 0 ? <><BsArrowUp color='green' size={12} /> <div>{seller.growth}%</div></> : <><BsArrowDown color='red' size={12} /> <div>{seller.growth}%</div></>) : '-'}</div>
                <div className='text-center w-[100px] px-3 py-2 flex-grow-0 flex-shrink-0 flex justify-start items-center gap-1'>
                    {seller.rating ? renderStars(stars) : '-'}
                </div>*/}
            </div>
        )
    }
}

export default withTranslation()(TableRowLight);