import React, { Component } from 'react'
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';

class GSTForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verify_type: "GST",
            gstin_number: '',
        }
    }
    sendDataToParent = () => {
        if (this.state.gstin_number === "") {
            toast.error("Please enter your GST Number");
        }
        else {
            const dataa = {
                gstin_number: this.state.gstin_number,
            };
            console.log('Calling sendDataToParent', dataa);
            this.props.verifyGST(dataa);
        }
    };
    render() {
        return (
            <div className='w-full p-3 justify-center bg-white rounded-md'>
                <div className='text-lg font-semibold'>{this.props.t("GSTForm.Verify GST/ MSME Details")}</div>
                <div className='text-xs text-gray-400 mb-6'>{this.props.t("GSTForm.Verify GST/ MSME Details1")}. </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.state.verify_type === "GST" ? `${this.props.t("GSTForm.Provisional GSTN")}` : `${this.props.t("GSTForm.MSME Number")}`}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder={`Enter your ${this.state.verify_type === "GST" ? 'Provisional GSTN' : 'MSME Number'}`} value={this.state.gstin_number} onChange={(e) => this.setState({ gstin_number: e.target.value })} />
                </div>
                <div className='flex flex-col justify-center items-start'>
                    <div className='text-xs text-[#5aa3b0] cursor-pointer' onClick={() => {
                        this.state.verify_type === "GST" ? this.setState({ verify_type: "MSME" }) : this.setState({ verify_type: "GST" })
                    }}>{this.state.verify_type === "GST" ? `${this.props.t("GSTForm.Verify with MSME Number")}` : `${this.props.t("GSTForm.Verify with Provisional GSTN")}`}</div>
                </div>
                <div className='my-3'>
                    <button
                        type="submit"
                        className={`w-fit bg-[#ffd814] text-black font-semibold py-1 px-8 text-center rounded-full shadow-md text-sm ${this.props.submitButtonDisbled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                        onClick={() => this.sendDataToParent()}
                        disabled={this.props.submitButtonDisbled}
                    >
                        <div className={`${!this.props.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            {this.props.t("Submit")}
                        </div>
                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.props.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            <ThreeDots
                                height="32"
                                width="30"
                                radius="9"
                                color="#000"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    </button>
                </div>
            </div>
        )
    }
}

export default withTranslation()(GSTForm);