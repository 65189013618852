import React, { Component } from 'react'
import TableHeading from './TableHeading'
import TableRowLight from './TableRowLight'
import TableRowDark from './TableRowDark'
import { BiSkipPrevious, BiSkipNext } from 'react-icons/bi'
import { withTranslation } from 'react-i18next'

class Pagination extends Component {
    render() {
        let { currentPage, totalPages, changePage } = this.props;
        if (totalPages <= 8) {
            return (
                Array.from({ length: totalPages }).map((_, pageNumber) => <div key={pageNumber} onClick={() => changePage(pageNumber + 1)} className={`h-[20px] px-1 bg-[#f2f3ff] border ${currentPage === (pageNumber + 1) && 'border-black'} rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0`}>{pageNumber + 1}</div>)
            )
        }
        else {
            if (currentPage <= 3 || currentPage >= (totalPages - 2)) {
                return (
                    Array.from({ length: totalPages }).map((_, pageNumber) => {
                        if (pageNumber <= 2) {
                            return <div key={pageNumber} onClick={() => changePage(pageNumber + 1)} className={`h-[20px] px-1 bg-[#f2f3ff] border ${currentPage === (pageNumber + 1) && 'border-black'} rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0`}>{pageNumber + 1}</div>
                        }
                        else if (pageNumber === 3) {
                            return <div>...</div>
                        } else if (pageNumber >= (totalPages - 3)) {
                            return <div key={pageNumber} onClick={() => changePage(pageNumber + 1)} className={`h-[20px] px-1 bg-[#f2f3ff] border ${currentPage === (pageNumber + 1) && 'border-black'} rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0`}>{pageNumber + 1}</div>
                        }
                    })
                )
            }
            else {
                return <>
                    <div onClick={() => changePage(1)} className={`h-[20px] px-1 bg-[#f2f3ff] border rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0`}>1</div>
                    <div onClick={() => changePage(2)} className={`h-[20px] px-1 bg-[#f2f3ff] border rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0`}>2</div>
                    <div>...</div>
                    <div className={`h-[20px] px-1 bg-[#f2f3ff] border border-black rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0`}>{currentPage}</div>
                    <div onClick={() => changePage(currentPage + 1)} className={`h-[20px] px-1 bg-[#f2f3ff] border rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0`}>{currentPage + 1}</div>
                    <div>...</div>
                    <div onClick={() => changePage(totalPages - 1)} className={`h-[20px] px-1 bg-[#f2f3ff] border rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0`}>{totalPages - 1}</div>
                    <div onClick={() => changePage(totalPages)} className={`h-[20px] px-1 bg-[#f2f3ff] border rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0`}>{totalPages}</div>
                </>
            }
        }
    }
}

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPages: null,
            currentPage: 1,
            start: 0
        }
    }
    componentDidMount() {
        this.setState({ totalPages: this.props.totalPages, currentPage: this.props.currentPage, start: (this.props.currentPage - 1) * 20 })
    }
    componentDidUpdate(prevProps) {
        if ((this.props.currentPage !== prevProps.currentPage) || (this.props.totalPages !== prevProps.totalPages)) {
            this.setState({ totalPages: this.props.totalPages, currentPage: this.props.currentPage, start: (this.props.currentPage - 1) * 20 });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
    render() {
        return (
            <>
                <div className='rounded-md overflow-x-auto custom-scrollbar-horizontal'>
                    <TableHeading />
                    {
                        (this.props.sellers.length > 0) ? this.props.sellers.map((seller, i) => {
                            if (i % 2 === 0) {
                                return <TableRowLight filters={this.props.filters} seller={seller} sn={this.state.start + i + 1} key={seller.seller_id} />
                            }
                            else {
                                return <TableRowDark filters={this.props.filters} seller={seller} sn={this.state.start + i + 1} key={seller.seller_id} />
                            }
                        }) :
                            <div className='text-sm p-3 text-red-700 w-full border border-black'>{this.props.t("Table.No Seller Found")}</div>
                    }
                </div>
                {
                    this.props.paginationAllowed && this.state.totalPages !== null && this.state.totalPages > 0 &&
                    <div className='flex justify-center items-center flex-row text-sm my-3 max-w-[300px] m-auto overflow-hidden select-none'>
                        <div onClick={() => {
                            if (this.state.currentPage > 1) {
                                this.props.changePage(this.state.currentPage - 1);
                            }
                        }} className='h-[20px] px-1 bg-[#f2f3ff] border rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0'><BiSkipPrevious /></div>
                        <Pagination totalPages={this.state.totalPages} currentPage={this.state.currentPage} changePage={this.props.changePage} />
                        <div onClick={() => {
                            if (this.state.currentPage < this.state.totalPages) {
                                this.props.changePage(this.state.currentPage + 1);
                            }
                        }} className='h-[20px] px-1 bg-[#f2f3ff] border rounded-sm flex justify-center items-center cursor-pointer mx-[5px] flex-shrink-0'><BiSkipNext /></div>
                    </div>
                }
            </>
        )
    }
}
export default withTranslation()(Table);