import React, { Component } from 'react'
import Topbar from '../Components/Topbar'
import homeImage from '../Assets/home-image.png'
import autoEarning from '../Assets/auto-earning.png'
import supportLocalBusiness from '../Assets/support-local-business.png'
import userFriendlyTools from '../Assets/user-friendly-tools.png'
import joinTheMovement from '../Assets/join-the-movement.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import quote1 from '../Assets/1.png'
import quote2 from '../Assets/2.png'
import quote3 from '../Assets/3.png'
import quote4 from '../Assets/4.png'
import logo from '../Assets/logo.png'
import Footer from '../Components/Footer'
import { Link, Navigate } from 'react-router-dom'
import about from '../Assets/Account-Management.jpg'
import { connect } from 'react-redux'
import HomeBar from '../Components/HomeBar.js'
import { withTranslation } from 'react-i18next'

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigateTo: null
        }
    }
    componentDidUpdate() {
        if (this.props.token !== null && this.props.agent_info && this.state.navigateTo === null) {
            this.setState({
                navigateTo: "/dashboard"
            })
        }
    }
    render() {
        return (
            <>
                {
                    this.state.navigateTo && <Navigate to={this.state.navigateTo} />
                }
                <HomeBar />
                <div className='px-5 max-w-[1000px] m-auto mt-[80px] sm:mt-[100px]'>
                    {/* <div className='relative'>
                        <img src={homeImage} alt="" className='w-full' />
                        <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col bg-[#00000090]'>
                            <div className='text-white text-base font-semibold sm:text-2xl'>Unlock Opportunities, Earn Commissions</div>
                            <Link to={'/signin'}>
                                <button className='mt-1 sm:mt-3 bg-[#ffd814] text-black rounded-full px-3 sm:px-5 py-1 sm:py-2 text-xs sm:text-sm shadow-md shadow-[#ffd81450] border border-black cursor-pointer font-semibold'>Continue</button>
                            </Link>
                        </div>
                    </div> */}
                    <div className='flex flex-wrap w-full'>
                        <div className='sm:w-[50%]  sm:text-start text-center'>
                            <div className='sm:w-[80%] sm:mt-16'>
                                <div className='text-[#150E4B] whitespace-nowrap sm:whitespace-normal text-[15px] font-semibold sm:text-2xl'>{this.props.t("HomePage.homepage1")}</div>
                                <div className='text-[#7D8091] text-xs sm:text-base'>{this.props.t("HomePage.homepage2")}.</div>
                            </div>
                            <Link to={'/signin'}>
                                <button className='mt-1 sm:mt-3 bg-[#ffd814] text-black rounded-full px-3 sm:px-5 py-1 sm:py-2 text-xs sm:text-sm shadow-md shadow-[#ffd81450] border border-black cursor-pointer font-semibold'>{this.props.t("Continue")}</button>
                            </Link>
                        </div>
                        <div className='sm:w-[50%] wrap'>
                            <img src={about} alt="" className='' />
                        </div>
                    </div>
                    <div className='mt-10 sm:mt-20 text-center'>
                        <div className='font-semibold text-[#150E4B] text-2xl'>{this.props.t("HomePage.homepage3")}</div>
                        <div className='text-sm text-[#7D8091] my-5'>{this.props.t("HomePage.homepage4")}.</div>
                        <div className='flex flex-row justify-center items-center w-full mt-10 sm:mt-20 flex-wrap'>
                            <div className='sm:w-[40%] md:w-[23%] m-1 p-6 h-[280px] hover:border-none hover:shadow-xl border border-solid border-blue-200'>
                                <div className='flex justify-center  items-center w-full'>
                                    <img src={autoEarning} className='w-[100px] aspect-square' alt="" />
                                </div>
                                <div>
                                    <div className='text-base text-[#150E4B] font-semibold my-3 text-center'>{this.props.t("HomePage.homepage5")}</div>
                                    <div className='text-xs font-medium text-[#7D8091]'>{this.props.t("HomePage.homepage6")}.</div>
                                </div>
                            </div>
                            <div className='sm:w-[40%] md:w-[23%] m-1 p-6 h-[280px] hover:border-none hover:shadow-xl border border-solid border-blue-200'>
                                <div className='flex justify-center items-center w-full'>
                                    <img src={supportLocalBusiness} className='w-[100px] aspect-square' alt="" />
                                </div>
                                <div>
                                    <div className='text-base text-[#150E4B] font-semibold my-3 text-center'>{this.props.t("HomePage.homepage7")}</div>
                                    <div className='text-xs font-medium text-[#7D8091]'>{this.props.t("HomePage.homepage8")}.</div>
                                </div>
                            </div>
                            <div className='sm:w-[40%] md:w-[23%] m-1 p-6 h-[280px] hover:border-none hover:shadow-xl border border-solid border-blue-200'>
                                <div className='flex justify-center items-center w-full'>
                                    <img src={userFriendlyTools} className='w-[100px] aspect-square' alt="" />
                                </div>
                                <div>
                                    <div className='text-base text-[#150E4B] font-semibold my-3 text-center'>{this.props.t("HomePage.homepage9")}</div>
                                    <div className='text-xs font-medium text-[#7D8091]'>{this.props.t("HomePage.homepage10")}.</div>
                                </div>
                            </div>
                            <div className='sm:w-[40%] md:w-[23%] m-1 p-6 h-[280px] hover:border-none hover:shadow-xl border border-solid border-blue-200'>
                                <div className='flex justify-center items-center w-full'>
                                    <img src={joinTheMovement} className='w-[100px] aspect-square' alt="" />
                                </div>
                                <div>
                                    <div className='text-base text-[#150E4B] font-semibold my-3 text-center'>{this.props.t("HomePage.homepage11")}</div>
                                    <div className='text-xs font-medium text-[#7D8091]'>{this.props.t("HomePage.homepage12")}.</div>
                                </div>
                            </div>
                        </div>
                        <div className='my-10 sm:my-20 w-full max-w-[600px] m-auto'>
                            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false} showArrows={false} interval="2000">
                                <div>
                                    <img src={quote1} />
                                </div>
                                <div>
                                    <img src={quote2} />
                                </div>
                                <div>
                                    <img src={quote3} />
                                </div>
                                <div>
                                    <img src={quote4} />
                                </div>
                            </Carousel>
                        </div>
                        <div>
                            <div className='text-xl font-semibold'>{this.props.t("HomePage.homepage13")}</div>
                            <div className='flex flex-row text-left justify-center items-start w-full flex-wrap'>
                                <div className='w-full sm:w-[50%] p-[20px]'>
                                    <div className='text-base font-semibold text-black tracking-wide'>{this.props.t("HomePage.homepage14")}?</div>
                                    <div className='text-gray-500 text-sm tracking-wide'>{this.props.t("HomePage.homepage15")}.</div>
                                </div>
                                <div className='w-full sm:w-[50%] p-[20px]'>
                                    <div className='text-base font-semibold text-black tracking-wide'>{this.props.t("HomePage.homepage16")}?</div>
                                    <div className='text-gray-500 text-sm tracking-wide'>{this.props.t("HomePage.homepage17")}.</div>
                                </div>
                                <div className='w-full sm:w-[50%] p-[20px]'>
                                    <div className='text-base font-semibold text-black tracking-wide'>{this.props.t("HomePage.homepage18")}?</div>
                                    <div className='text-gray-500 text-sm tracking-wide'>{this.props.t("HomePage.homepage19")}.</div>
                                </div>
                                <div className='w-full sm:w-[50%] p-[20px]'>
                                    <div className='text-base font-semibold text-black tracking-wide'>{this.props.t("HomePage.homepage20")}?</div>
                                    <div className='text-gray-500 text-sm tracking-wide'>{this.props.t("HomePage.homepage21")}.</div>
                                </div>
                            </div>
                        </div>
                        <div className='my-10 sm:my-20 bg-[#fff9e9] w-full p-[20px] sm:p-[40px] flex-wrap sm:flex-nowrap flex flex-row justify-center items-center'>
                            <img src={logo} className='w-[100px] sm:w-[150px]' alt="" />
                            <div className='w-full mt-4 sm:mt-0 sm:flex-shrink sm:ml-10'>
                                <p className='text-sm text-gray-500'>{this.props.t("HomePage.homepage22")}!</p>
                                <Link to={'/signin'}>
                                    <button className='mt-3 bg-[#ffd814] text-black rounded-full px-5 py-1 text-xs shadow-md shadow-[#ffd81450] border border-black cursor-pointer'>{this.props.t("Continue")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        agent_info: state.agent_info
    };
};

export default connect(mapStateToProps, null)(withTranslation()(HomePage));