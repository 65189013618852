import React, { Component } from 'react'
import { BsImageFill } from 'react-icons/bs'
import PropTypes from 'prop-types';
import { ThreeDots } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';

class AddSellerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopName: '',
            sellerName: '',
            email: '',
            mobile: '',
            gender: '',
            password: '',
            termsAccepted: false,
        };
    }
    sendDataToParent = () => {
        if (this.state.shopName === "") {
            toast.error("Please enter Shop Name");
        }
        else if (this.state.sellerName === "") {
            toast.error("Please enter Seller Name");
        }
        else if (this.state.email === "") {
            toast.error("Please enter your E-Mail");
        }
        else if (this.state.mobile.length != 10) {
            toast.error("Please enter correct Mobile Number");
        }
        else if (this.state.gender === "") {
            toast.error("Please select your gender");
        }
        else if (this.state.password === "") {
            toast.error("Please enter a password");
        }
        else if (!this.state.termsAccepted) {
            toast.error("Please accept the terms and conditions");
        }
        else {
            const data = {
                number: this.state.mobile,
                shop_name: this.state.shopName,
                gender: this.state.gender,
                dob: this.state.dob,
                seller_name: this.state.sellerName,
                email: this.state.email,
                password: this.state.password,
            };
            this.props.sendOTP(data);
        }
    };

    render() {
        return (
            <div className='w-full p-3  rounded-md'>
                <div className='text-lg font-semibold'>{this.props.t("AddSellerForm.Add New Seller")}</div>
                <div className='text-xs text-gray-400 mb-6'>{this.props.t("AddSellerForm.Add New Seller1")}</div>
               
                <div className='my-6 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("AddSellerForm.Shop Name")}</div>
                    <input type="text" className='w-full border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='Enter your Shop Name'
                        value={this.state.shopName}
                        onChange={(e) => this.setState({ shopName: e.target.value })} />
                </div>
                <div className='my-6 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("AddSellerForm.Seller Name")}</div>
                    <input type="text" className='w-full border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='Enter your Full Name'
                        value={this.state.sellerName}
                        onChange={(e) => this.setState({ sellerName: e.target.value })} />
                </div>
                <div className='my-6 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("AddSellerForm.E-Mail")}</div>
                    <input type="email" className='w-full border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='Enter your Email Address'
                        value={this.state.email}
                        onChange={(e) => this.setState({ email: e.target.value })} />
                </div>
                <div className='my-6 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("AddSellerForm.Mobile Number")}</div>
                    <input type="text" className='w-full border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='Enter your Mobile Number'
                        value={this.state.mobile}
                        onChange={(e) => this.setState({ mobile: e.target.value })} />
                </div>
                <div className='my-6 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("AddSellerForm.Date of Birth")}</div>
                    <input
                        type="date"
                        className='w-full border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs'
                        placeholder='Enter your Date of Birth'
                        value={this.state.dob}
                        onChange={(e) => this.setState({ dob: e.target.value })}
                    />
                </div>
                <div className='my-6 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("AddSellerForm.Gender")}</div>
                    <select name="gender" id="gender" className='w-full border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs'
                        value={this.state.gender}
                        onChange={(e) => this.setState({ gender: e.target.value })}
                    >
                        <option value="">{this.props.t("AddSellerForm.Select your Gender")}</option>
                        <option value="Male">{this.props.t("AddSellerForm.Male")}</option>
                        <option value="Female">{this.props.t("AddSellerForm.Female")}</option>
                        <option value="Other">{this.props.t("AddSellerForm.Other")}</option>
                    </select>
                </div>
                <div className='my-6 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("AddSellerForm.Password")}</div>
                    <input type="password" className='w-full border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='Enter a password'
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })} />
                </div>
                <div className='my-6 flex flex-row  items-center'>
                    <input type="checkbox" name="termsacept" id="termsaccept"
                        checked={this.state.termsAccepted}
                        onChange={(e) => this.setState({ termsAccepted: e.target.checked })} />
                    <div className='text-xs font-semibold text-black ml-2'>{this.props.t("AddSellerForm.termsaccept")}</div>
                </div>
                <div className='my-6'>
                    <button
                        type="submit"
                        className={`bg-[#FFD814]  text-center py-1 px-8 text-sm font-semibold rounded-full shadow-md ${this.props.submitButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                        onClick={() => this.sendDataToParent()}
                        disabled={this.props.submitButtonDisabled}
                    >
                        <div className={`${!this.props.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                        {this.props.t("Submit")}
                        </div>
                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.props.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            <ThreeDots
                                height="32"
                                width="30"
                                radius="9"
                                color="#000"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    </button>
                </div>
            </div>
        )
    }
}
AddSellerForm.propTypes = {
    sendOTP: PropTypes.func.isRequired,
};

export default withTranslation()(AddSellerForm);