import React, { Component } from 'react'
import Footer from '../Components/Footer'
import AccessDenied from '../Components/ProtectRoutes/AccessDenied'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Navbar from '../Components/Navbar';
import SideDocs from '../Components/SideDocs';
import Documentation from '../Components/Docs/Documentation';
import NavbarDocs from '../Components/Docs/NavbarDocs';

class Docs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_tab: null,
            breadcrumbs: [
                {
                    name: 'Docs',
                    link: '/docs'
                }
            ]
        }
    }
    render() {
        return (
            <>
                {this.props.token ? (
                    <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                        <div className='w-fit h-fit z-50 fixed'>
                            <SideDocs />
                        </div>
                        <div className='w-full md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
                            <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                                <NavbarDocs breadcrumbs={this.state.breadcrumbs} />
                            </div>
                            <div className='px-3 my-[60px] md:mt-[50px]'>
                                <div className='flex-shrink-0 flex-grow-0 flex flex-col overflow-y-auto overflow-x-hidden'>
                                    <div className='w-full rounded-md p-3 mt-3 block md:flex flex-row justify-center items-stretch gap-2'>
                                       <Documentation />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full mt-3 absolute bottom-0'>
                                <Footer />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
export default connect(mapStateToProps, null)(withTranslation()(Docs));