import React, { Component } from 'react'
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { logout } from '../../actions';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            otp: '',
            PasswordButtonDisbled: false,
            updateButtonDisabled: false,
            otpSent: false
        }
    }
    render() {
        const sentOtp = () => {
            this.setState({ updateButtonDisabled: true })
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/pass_sendOtp`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success(result.msg)
                            this.setState({ updateButtonDisabled: false, otpSent: true })
                        }
                        else {
                            toast.error(result.msg)
                            this.setState({ updateButtonDisabled: false })
                        }
                    }
                })
                .catch((error) => {
                    this.setState({ updateButtonDisabled: false });
                    toast.error("Server Error")
                });
        }
        const submit = () => {
            this.setState({ PasswordButtonDisbled: true })
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                password: this.state.password,
                OTP: this.state.otp
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/editpassword`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success(result.msg)
                            this.setState({ PasswordButtonDisbled: false, otpSent: false })
                        }
                        else {
                            toast.error(result.msg)
                            this.setState({ PasswordButtonDisbled: false })
                        }
                    }
                })
                .catch((error) => {
                    this.setState({ PasswordButtonDisbled: false });
                    toast.error("Server Error")
                });
        }
        return (
            <div className='p-3'>
                <div className='text-lg font-semibold'>{this.props.t("ChangePassword.Profile Information")}</div>
                <div className='text-xs text-gray-400'>{this.props.t("ChangePassword.Profile Information1")}.</div>
                <div className='text-xs'>
                    {
                        !this.state.otpSent ?
                            <>
                                <div className='my-6 justify-start items-center'>
                                    <div className='font-semibold'>{this.props.t("ChangePassword.Email Address")} :</div>
                                    <input
                                        className="block my-[2.5px] mt-2 w-[250px] sm:w-[350px] rounded-[50px] px-[10px] py-[4px] text-xs border-solid border-[1px] border-[#697475]"
                                        type="text"
                                        name="email"
                                        value={this.props.agent_info.email}
                                        required
                                        disabled
                                    />
                                </div>
                                <div className='my-6 flex justify-start items-center'>
                                    <button
                                        className={`bg-[#FFD814] w-full md:w-1/5 text-center py-2 text-sm font-semibold rounded-full shadow-md ${this.state.updateButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                                        onClick={() => sentOtp()}
                                        disabled={this.state.updateButtonDisabled}
                                    >
                                        <div className={`${!this.state.updateButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            {this.props.t("Sent OTP")}
                                        </div>
                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.updateButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            <ThreeDots
                                                height="32"
                                                width="30"
                                                radius="9"
                                                color="#000"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <div className='my-6 w-full md:w-1/4 justify-start items-center'>
                                    <div className='font-semibold'>{this.props.t("ChangePassword.New Password")} :</div>
                                    <div className='ml-2'>
                                        <input type="text" className='border border-black rounded-md w-full p-2 text-sm mt-1' placeholder='Enter Password' value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                    </div>
                                </div>
                                <div className='my-6 w-full md:w-1/4 justify-start items-center'>
                                    <div className='font-semibold'>{this.props.t("ChangePassword.OTP")} :</div>
                                    <div className='ml-2'>
                                        <input type="text" className='border border-black rounded-md w-full p-2 text-sm mt-1' placeholder='Enter OTP Received' value={this.state.otp} onChange={(e) => this.setState({ otp: e.target.value })} />
                                    </div>
                                </div>
                                <div className='my-6 flex justify-start items-center'>
                                    <button
                                        type="submit"
                                        className={`bg-[#FFD814] w-full md:w-1/4 text-center py-2 text-sm font-semibold rounded-full shadow-md ${this.state.PasswordButtonDisbled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                                        onClick={() => submit()}
                                        disabled={this.state.PasswordButtonDisbled}
                                    >
                                        <div className={`${!this.state.PasswordButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            {this.props.t("Submit")}
                                        </div>
                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.PasswordButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            <ThreeDots
                                                height="32"
                                                width="30"
                                                radius="9"
                                                color="#000"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    </button>
                                </div>
                            </>
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
        agent_info: state.agent_info
    };
};
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangePassword));