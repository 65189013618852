import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class Profile extends Component {
    render() {
        return (
            <div className='p-3'>
                <div className='text-lg font-semibold'>{this.props.t("Profile.Profile Information")}</div>
                <div className='text-xs text-gray-400'>{this.props.t("Profile.Profile Information1")}.</div>
                <div className='text-xs'>
                    <div className='my-6 flex justify-start items-center'>
                        <div className='font-semibold'>{this.props.t("Profile.Name")} :</div>
                        <div className='ml-2'>{this.props.agent_info.name}</div>
                    </div>
                    <div className='my-6 flex justify-start items-center'>
                        <div className='font-semibold'>{this.props.t("Profile.Email Address")} :</div>
                        <div className='ml-2'>{this.props.agent_info.email}</div>
                    </div>
                    <div className='my-6 flex justify-start items-center'>
                        <div className='font-semibold'>{this.props.t("Profile.Mobile Number")} :</div>  
                        <div className='ml-2'>{this.props.agent_info.number}</div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
        agent_info: state.agent_info
    };
};

export default connect(mapStateToProps, null)(withTranslation()(Profile));