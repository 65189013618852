import React, { Component } from 'react'
import { BiSolidShoppingBags } from 'react-icons/bi'

export default class SellerCard extends Component {
    render() {
        return (
            <div className='w-[200px] h-[100px] bg-white rounded-md flex-grow-0 flex-shrink-0 p-3 flex flex-col justify-between items-start relative'>
                <div className='bg-white shadow-md p-2 rounded-full absolute top-2 right-2'>
                    <BiSolidShoppingBags size={20} color='#3b62ad' />
                </div>
                <div className='text-2xl font-semibold text-black'>{this.props.number}</div>
                <div className='text-base font-medium text-black'>{this.props.status}</div>
                {/* <div className='flex w-full justify-between items-center'>
                    <div className='flex justify-center items-center'>
                        <AiOutlineRise color='green' size={20} />
                        <div className='text-sm ml-1'>10.5%</div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <AiOutlineRise color='green' size={20} />
                        <div className='text-sm ml-1'>6</div>
                    </div>
                </div> */}
            </div>
        )
    }
}
