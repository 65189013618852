import React, { Component } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaBars, FaTimes } from 'react-icons/fa';
import Topbar from './Topbar';
import { withTranslation } from 'react-i18next';

class Navbar extends Component {
    constructor(props) {
        super(props);
        const storedOption = localStorage.getItem('selectedLanguage');
        this.state = {
            sideDiv: false,
            selectedLanguage: storedOption ? storedOption : 'en',
        };
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }
    handleLanguageChange = (event) => {
        const { i18n } = this.props;
        i18n.changeLanguage(event.target.value);
        const selectedLanguage = event.target.value;
        this.setState({ selectedLanguage });
        localStorage.setItem('selectedLanguage', selectedLanguage);
    };
    render() {
        const show = () => {
            this.setState({ sideDiv: true })
        }
        const hide = () => {
            this.setState({ sideDiv: false })
        }
        return (
            <>
                <div className='w-full bg-white py-2 px-4 md:px-8 hidden md:flex flex-row justify-between flex-wrap border-b-2 border-[#041737] flex-grow'>
                    <div>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            {
                                this.props.breadcrumbs.map((breadcrumb, i) => {
                                    if (i === (this.props.breadcrumbs.length - 1)) {
                                        return (<Link key={i} className='text-xs md:text-sm text-black'>{breadcrumb.name}</Link>)
                                    }
                                    else {
                                        return (<Link key={i} to={breadcrumb.link} className='text-xs md:text-sm text-gray-400'>{breadcrumb.name}</Link>)
                                    }
                                })
                            }
                        </Breadcrumbs>
                    </div>
                    <div className='flex flex-row justify-start items-center flex-wrap'>
                        <Link to={'/docs'}><div className='p-1 text-xs rounded-lg cursor-pointer border border-blue-300'>Docs</div></Link>
                        <select name="langauge" id="language" className='text-xs px-2 bg-white focus:outline-none' value={this.state.selectedLanguage} onChange={this.handleLanguageChange}>
                            <option value="en" className='text-xs px-2 bg-white'>English - EN</option>
                            <option value="hi" className='text-xs px-2 bg-white'>हिन्दी - HI</option>
                            <option value="pa" className='text-xs px-2 bg-white'>ਪੰਜਾਬੀ - PA</option>
                        </select>
                        {this.props.token ?
                            <Link to={'/settings'}><div className='font-semibold cursor-pointer text-xs md:text-sm md:ml-5'>{this.props.t("Welcome Back")}, {this.props.agent_info?.name}</div></Link> :
                            <Link to={'/signin'}><div className='font-semibold cursor-pointer text-xs md:text-sm md:ml-5'>{this.props.t("Sign In")}</div></Link>
                        }
                    </div>
                </div>

                <div className='w-full bg-white py-2 px-4 md:px-8 md:hidden block border-b-2 border-[#041737] flex-grow'>
                    <div className='flex flex-row justify-between items-center flex-wrap'>
                        <div className="md:hidden cursor-pointer" onClick={() => show()}><FaBars size={20} /></div>
                        {this.state.sideDiv &&
                            <Topbar hide={hide} />

                        }
                        <div className='flex flex-row justify-end items-center flex-wrap'>
                            <Link to={'/docs'}><div className='p-1 text-xs rounded-lg cursor-pointer border border-blue-300'>Docs</div></Link>
                            <select name="langauge" id="language" className='text-xs px-2 bg-white focus:outline-none' value={this.state.selectedLanguage} onChange={this.handleLanguageChange}>
                                <option value="en" className='text-xs px-2 bg-white'>English - EN</option>
                                <option value="hi" className='text-xs px-2 bg-white'>हिन्दी - HI</option>
                                <option value="pa" className='text-xs px-2 bg-white'>ਪੰਜਾਬੀ - PA</option>
                            </select>
                            {this.props.token ?
                                <Link to={'/settings'}><div className='font-semibold cursor-pointer text-xs md:text-sm md:ml-5'>{this.props.t("Welcome Back")}, {this.props.agent_info?.name}</div></Link> :
                                <Link to={'/signin'}><div className='font-semibold cursor-pointer text-xs md:text-sm md:ml-5'>{this.props.t("Sign In")}</div></Link>
                            }
                        </div>
                    </div>
                    <div className='mt-2'>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            {
                                this.props.breadcrumbs.map((breadcrumb, i) => {
                                    if (i === (this.props.breadcrumbs.length - 1)) {
                                        return (<Link key={i} className='text-xs md:text-sm text-black'>{breadcrumb.name}</Link>)
                                    }
                                    else {
                                        return (<Link key={i} to={breadcrumb.link} className='text-xs md:text-sm text-gray-400'>{breadcrumb.name}</Link>)
                                    }
                                })
                            }
                        </Breadcrumbs>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        agent_info: state.agent_info
    };
};

export default connect(mapStateToProps, null)(withTranslation()(Navbar));