import React, { Component } from 'react'
import SideDocs from '../SideDocs'
import Footer from '../Footer'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AccessDenied from '../ProtectRoutes/AccessDenied';
import { MdPeopleAlt } from 'react-icons/md';
import NavbarDocs from './NavbarDocs';

class ManageSeller extends Component {
constructor(props) {
      super(props);
    this.state = {
      current_tab: null,
      breadcrumbs: [
        {
          name: 'Docs',
          link: '/docs'
        },
        {
          name: 'ManageSeller',
          link: '/docs/ManageSeller'
        }
      ]
    }
  }
  render() {
    return (
      <>
        {this.props.token ? (
          <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
            <div className='w-fit h-fit z-50 fixed'>
              <SideDocs />
            </div>
            <div className='w-full md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
              <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                <NavbarDocs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className='px-3 my-[60px] md:mt-[50px]'>
                <div className='flex-shrink-0 flex-grow-0 flex flex-col overflow-y-auto overflow-x-hidden'>
                  <div className='w-full rounded-md p-3 mt-3 block md:flex flex-row justify-center items-stretch gap-2'>
                    <div className="w-full text-xs md:text-base md:p-6">
                      <div className="w-full">
                        <h1 className="text-2xl font-medium">{this.props.t("docs.Documentation")}</h1>
                      </div>
                      <div className="w-full mt-8">
                        <h2 className="text-lg font-medium flex items-center gap-2 bg-slate-50 border border-slate-300 p-2 rounded mt-4">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                          </svg>
                          {this.props.t("docs.ManageSeller")}
                        </h2>
                        <article className="mt-2 text-justify">{this.props.t("docs.ManageSeller1")}</article>

                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                          <MdPeopleAlt size={20} />
                            {this.props.t("docs.ManageSeller2")}
                          </h3>
                          <ul className="mt-2 list-disc list-inside text-justify ml-2">
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller3")}</span>{this.props.t("docs.ManageSeller4")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller5")}</span>{this.props.t("docs.ManageSeller6")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller7")}</span>{this.props.t("docs.ManageSeller8")}</li>
                          </ul>
                          <article className="mt-2 text-justify">{this.props.t("docs.ManageSeller9")}</article>
                        </div> 
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                            </svg>
                            {this.props.t("docs.ManageSeller10")}
                          </h3>
                          <article className="mt-2 text-justify">{this.props.t("docs.ManageSeller11")}</article>
                          <ul className="mt-2 list-disc list-inside text-justify ml-2">
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller12")}</span>{this.props.t("docs.ManageSeller13")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller14")}</span>{this.props.t("docs.ManageSeller15")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller16")}</span>{this.props.t("docs.ManageSeller17")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller18")}</span>{this.props.t("docs.ManageSeller19")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller20")}</span>{this.props.t("docs.ManageSeller21")}</li>
                          </ul>
                        </div>
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                            </svg>
                            {this.props.t("docs.ManageSeller22")}
                          </h3>
                          <article className="mt-2 text-justify">{this.props.t("docs.ManageSeller23")}</article>
                          <ul className="mt-2 list-disc list-inside text-justify ml-2">
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller24")}</span>{this.props.t("docs.ManageSeller25")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller26")}</span>{this.props.t("docs.ManageSeller27")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller28")}</span>{this.props.t("docs.ManageSeller29")}</li>
                          </ul>
                        </div>
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                            </svg>
                            {this.props.t("docs.ManageSeller30")}
                          </h3>
                          <article className="mt-2 text-justify">{this.props.t("docs.ManageSeller31")}</article>
                          <h3 className="text-base font-bold flex items-center gap-2">
                            {this.props.t("docs.ManageSeller32")}
                          </h3>
                          <ul className="mt-2 list-inside text-justify ml-2">
                            <li>{this.props.t("docs.ManageSeller33")}</li>
                            <li>{this.props.t("docs.ManageSeller34")}</li>
                          </ul>
                          <h3 className="text-base font-bold flex items-center gap-2">
                            {this.props.t("docs.ManageSeller35")}
                          </h3>
                          <ul className="mt-2 list-disc list-inside text-justify ml-2">
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller36")}</span>{this.props.t("docs.ManageSeller37")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller38")}</span>{this.props.t("docs.ManageSeller39")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.ManageSeller40")}</span>{this.props.t("docs.ManageSeller41")}</li>
                          </ul>
                          <ul className="mt-2 list-inside text-justify ml-2">
                            <li>{this.props.t("docs.ManageSeller42")}</li>
                          </ul>
                          <h3 className="text-base font-bold flex items-center gap-2">
                            {this.props.t("docs.ManageSeller43")}
                          </h3>
                          <ul className="mt-2 list-inside text-justify ml-2">
                            <li>{this.props.t("docs.ManageSeller44")}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full mt-3 absolute bottom-0'>
                <Footer />
              </div>
            </div>
          </div>
        ) : (
          <div><AccessDenied /></div>
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(withTranslation()(ManageSeller));