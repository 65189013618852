import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// resources
import enTranslation from "./locales/en.json";
import hiTranslation from "./locales/hi.json";
import paTranslation from "./locales/pa.json";

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: "en",
    // fallbackLng: "en",
    debug: true,
    resources: {
      en: { translation: enTranslation },
      hi: { translation: hiTranslation },
      pa: { translation: paTranslation },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
