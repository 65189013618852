import React, { Component } from 'react'
import SideDocs from '../SideDocs'
import Footer from '../Footer'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AccessDenied from '../ProtectRoutes/AccessDenied';
import { MdPeopleAlt } from 'react-icons/md';
import NavbarDocs from './NavbarDocs';

class DocSettings extends Component {
constructor(props) {
      super(props);
    this.state = {
      current_tab: null,
      breadcrumbs: [
        {
          name: 'Docs',
          link: '/docs'
        },
        {
          name: 'Settings',
          link: '/docs/Settings'
        }
      ]
    }
  }
  render() {
    return (
      <>
        {this.props.token ? (
          <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
            <div className='w-fit h-fit z-50 fixed'>
              <SideDocs />
            </div>
            <div className='w-full md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
              <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                <NavbarDocs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className='px-3 my-[60px] md:mt-[50px]'>
                <div className='flex-shrink-0 flex-grow-0 flex flex-col overflow-y-auto overflow-x-hidden'>
                  <div className='w-full rounded-md p-3 mt-3 block md:flex flex-row justify-center items-stretch gap-2'>
                    <div className="w-full text-xs md:text-base md:p-6">
                      <div className="w-full">
                        <h1 className="text-2xl font-medium">{this.props.t("docs.Documentation")}</h1>
                      </div>
                      <div className="w-full mt-8">
                        <h2 className="text-lg font-medium flex items-center gap-2 bg-slate-50 border border-slate-300 p-2 rounded mt-4">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                          </svg>
                          {this.props.t("docs.Settings2")}
                        </h2>

                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                          <MdPeopleAlt size={20} />
                            {this.props.t("docs.Settings3")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.Settings4")}</span>{this.props.t("docs.Settings5")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.Settings6")}</span>{this.props.t("docs.Settings7")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.Settings8")}</span>{this.props.t("docs.Settings9")}</li>
                          </ul>
                        </div> 
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                          <MdPeopleAlt size={20} />
                            {this.props.t("docs.Settings10")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.Settings11")}</span>{this.props.t("docs.Settings12")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.Settings13")}</span>{this.props.t("docs.Settings14")}</li>
                          </ul>
                        </div> 
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                          <MdPeopleAlt size={20} />
                            {this.props.t("docs.Settings15")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.Settings16")}</span>{this.props.t("docs.Settings17")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.Settings18")}</span>{this.props.t("docs.Settings19")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.Settings20")}</span>{this.props.t("docs.Settings21")}</li>
                          </ul>
                        </div> 
                        
                    <article className="mt-2 text-justify">{this.props.t("docs.Settings22")}</article>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full mt-3 absolute bottom-0'>
                <Footer />
              </div>
            </div>
          </div>
        ) : (
          <div><AccessDenied /></div>
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(withTranslation()(DocSettings));