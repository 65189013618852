import React, { Component } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';
import AddSellerForm from '../Components/AddSeller.js/AddSellerForm';
import VerifyOTPForm from '../Components/AddSeller.js/VerifyOTPForm';
import { Navigate } from 'react-router-dom';
import GSTForm from '../Components/Onboarding/GSTForm';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { logout } from '../actions';
import AccessDenied from '../Components/ProtectRoutes/AccessDenied';
const withRouter = WrappedComponent => props => {
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            params={params}
        />
    );
};

class AddSeller extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formFilled: false,
            sellerVerifed: false,
            onboarding_state: "GST/MSME",
            mobile: null,
            seller_data: null,
            seller_id: null,
            verifyButtonDisabled: false,
            submitButtonDisabled: false
        }
    }
    render() {
        const verifySeller = (otp) => {
            this.setState({ verifyButtonDisabled: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                number: this.state.mobile,
                OTP: otp
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            // console.log(raw)


            fetch(`${process.env.REACT_APP_API_URL}/signup_verify_otp`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success('Verified Successfully')
                            this.setState({ sellerVerifed: true })
                        } else {
                            toast.error(result.msg)
                        }
                        this.setState({ verifyButtonDisabled: false });
                    }
                })
                .catch((error) => {
                    toast.error("Server Error")
                });
        }
        const sendOTP = (data) => {
            this.setState({ submitButtonDisabled: true })
            // const data = data
            this.setState({ seller_data: data });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(data);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/seller_signup`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success(result.msg)
                            this.setState({ formFilled: true, mobile: data.number, seller_id: result.seller_id, submitButtonDisabled: false });
                        } else {
                            toast.error(result.msg)
                            this.setState({ submitButtonDisabled: false });
                        }
                    }
                })
                .catch((error) => {
                    toast.error("Server Error")
                });
                this.setState({ submitButtonDisabled: false });
        }
        let breadcrumbs = [
            {
                name: 'Sellers',
                link: '/sellers'
            },
            {
                name: 'Add New Seller',
                link: '/add-seller'
            },
        ]
        return (
            <>
                {this.props.token ? (
                    <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                        <div className='w-fit h-fit z-50 fixed'>
                            <Sidebar />
                        </div>
                        <div className='w-full md:w-[calc(100%-250px)] md:justify-center md:flex ml-auto min-h-screen relative overflow-x-hidden'>
                            <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                                <Navbar breadcrumbs={breadcrumbs} />
                            </div>
                            {
                                !this.state.sellerVerifed &&
                                <div className='px-3 max-w-[600px] bg-white my-[80px] md:my-[50px]'>
                                    {
                                        !this.state.formFilled ? <AddSellerForm sendOTP={sendOTP} submitButtonDisabled={this.state.submitButtonDisabled} /> : <VerifyOTPForm verifyButtonDisabled={this.state.verifyButtonDisabled} verifySeller={verifySeller} seller_data={this.state.seller_data} />
                                    }
                                </div>
                            }
                            {
                                this.state.sellerVerifed && <Navigate to={`/onboarding/${this.state.seller_id}`} />
                            }
                            <div className='w-full mt-3 absolute bottom-0'>
                                <Footer />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddSeller));