import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class error404 extends Component {
    render() {
        
//   console.log("Not Found Component Rendered");
        return (
            <div className='w-full'>
                <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
                    <h1 className="text-5xl font-bold mb-4">404 Error: Page Not Found</h1>
                    <p className="text-lg text-gray-700 mb-6">Oops! It seems like you’ve stumbled upon a missing page.</p>
                    <p className="text-lg text-gray-700 mb-6">Don’t worry, let’s get you back on track. You can try one of the following options:</p>
                    <ul className="text-lg text-gray-700 mb-6 list-disc">
                        <li className="mb-2">Double-check the URL for any typos.</li>
                        <li className="mb-2">Go back to the previous page and try again.</li>
                        <li className="mb-2">Explore our popular pages and discover something new.</li>
                        <li className="mb-2">Contact our support team for further assistance.</li>
                    </ul>
                    <div className="w-full inline-flex justify-center">
                        <Link className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded" to={'/'}>Go to Homepage</Link>
                    </div>
                </div>
            </div>
        )
    }
}
