import React, { Component } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';
import { connect } from 'react-redux';
import { Link, useParams, useHistory, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { BsImageFill } from 'react-icons/bs'
import { withTranslation } from 'react-i18next';
import { logout } from '../actions';
import toast from 'react-hot-toast';
import { BiArrowBack } from 'react-icons/bi';
import AccessDenied from '../Components/ProtectRoutes/AccessDenied';

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const location = useLocation();

    return (
        <WrappedComponent
            {...props}
            params={params}
            location={location}
        />
    );
};

class SingleSeller extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seller_data: null,
            navigateTo: null
        }
    }
    componentDidMount() {
        if (!this.state.seller_data && this.props.token) {
            const { id } = this.props.params;
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/get_single_sellers/${id}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                    if (res.code === 401 || res.code === 407) {
                        this.props.logoutDispatch();

                    }
                    else {
                        if (res.status === "Success" || res.status === "success") {
                            this.setState({ seller_data: res.seller_data })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    componentDidUpdate() {
        if (!this.state.seller_data && this.props.token) {
            const { id } = this.props.params;
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/get_single_sellers/${id}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                    if (res.code === 401 || res.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (res.status === "Success" || res.status === "success") {
                            this.setState({ seller_data: res.seller_data })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    render() {
        let breadcrumbs = [
            {
                name: 'Sellers',
                link: '/sellers'
            },
            {
                name: 'Seller Details',
                link: '/seller-details'
            }
        ];
        const handleBack = () => {
            // letFilters = this.props.location.state.filters;
            this.setState({ navigateTo: '/sellers' })
        }
        return (
            <>
                {this.props.token ? (
                    <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                        <div className='w-fit h-fit z-50 fixed'>
                            <Sidebar />
                        </div>
                        {this.state.navigateTo && <Navigate to={this.state.navigateTo} />}
                        <div className='md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
                            <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                                <Navbar breadcrumbs={breadcrumbs} />
                            </div>
                            <div className='px-3 my-[80px] md:my-[50px]'>
                                <div className='bg-white rounded-md p-3 '>
                                    {/* {
                                        this.props.location.state.filters &&
                                       } */} <Link className='flex flex-row justify-center items-center text-xs mb-3 gap-2 border border-black rounded-full w-fit px-2 cursor-pointer' to={'/sellers'}><BiArrowBack color="black" />Back</Link>

                                    <div className='text-lg font-semibold'>{this.props.t("SingleSeller.Seller Details")}</div>
                                    <div className='text-xs text-gray-400'>{this.props.t("SingleSeller.Seller Details1")}</div>
                                    {
                                        this.state.seller_data &&
                                        <>
                                            <div className="lg:flex block">
                                                {/* Left Side */}
                                                <div className="lg:w-3/5 w-full whitespace-nowrap text-xs md:text-lg flex flex-wrap p-2 md:p-4">
                                                    <div className="w-1/2 md:p-2 p-1">
                                                        <div className='bg-blue-200 md:font-medium md:p-4 p-3'>
                                                            <h2>{this.props.t("SingleSeller.Total Product")}</h2>
                                                            <p className='p-1'>10</p>
                                                        </div>
                                                    </div>
                                                    <div className="w-1/2 md:p-2 p-1">
                                                        <div className='bg-green-200 md:font-medium md:p-4 p-3'>
                                                            <h2>{this.props.t("SingleSeller.Listed Product")}</h2>
                                                            <p className='p-1'>10</p>
                                                        </div>
                                                    </div>
                                                    <div className="w-1/2 md:p-2 p-1">
                                                        <div className='bg-yellow-200 md:font-medium md:p-4 p-3'>
                                                            <h2>{this.props.t("SingleSeller.Unlisted Product")}</h2>
                                                            <p className='p-1'>10</p>
                                                        </div>
                                                    </div>
                                                    <div className="w-1/2 md:p-2 p-1">
                                                        <div className='bg-pink-200 md:font-medium md:p-4 p-3'>
                                                            <h2>{this.props.t("SingleSeller.Total Sales")}</h2>
                                                            <p className='p-1'>10</p>
                                                        </div>
                                                    </div>
                                                    <div className="w-1/2 md:p-2 p-1">
                                                        <div className='bg-purple-200 md:font-medium md:p-4 p-3'>
                                                            <h2>{this.props.t("SingleSeller.Total Order")}</h2>
                                                            <p className='p-1'>10</p>
                                                        </div>
                                                    </div>
                                                    <div className="w-1/2 md:p-2 p-1">
                                                        <div className='bg-red-200 md:font-medium md:p-4 p-3'>
                                                            <h2>{this.props.t("SingleSeller.Revenue Generated")}</h2>
                                                            <p className='p-1'>{this.props.t("Rs")}. 50780</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Right Side */}
                                                <div className="lg:w-2/5 w-full whitespace-nowrap bg-white p-4">
                                                    <div className='flex justify-start items-center flex-row my-2 text-sm'>
                                                        <div className='font-semibold mr-2 w-[150px]'>{this.props.t("SingleSeller.Seller Name")} : </div>
                                                        <div>{this.state.seller_data.seller_name}</div>
                                                    </div>
                                                    <div className='flex justify-start items-center flex-row my-2 text-sm'>
                                                        <div className='font-semibold mr-2 w-[150px]'>{this.props.t("SingleSeller.Shop Name")} : </div>
                                                        <div>{this.state.seller_data.shop_name}</div>
                                                    </div>
                                                    <div className='flex justify-start items-center flex-row my-2 text-sm'>
                                                        <div className='font-semibold mr-2 w-[150px]'>{this.props.t("SingleSeller.Mobile Number")} : </div>
                                                        <div>{this.state.seller_data.number}</div>
                                                    </div>
                                                    <div className='flex justify-start items-center flex-row my-2 text-sm'>
                                                        <div className='font-semibold mr-2 w-[150px]'>{this.props.t("SingleSeller.E-Mail")} : </div>
                                                        <div>{this.state.seller_data.email}</div>
                                                    </div>
                                                    <div className='flex justify-start items-center flex-row my-2 text-sm'>
                                                        <div className='font-semibold mr-2 w-[150px]'>{this.props.t("SingleSeller.Date of Birth")} : </div>
                                                        <div>{this.state.seller_data.dob}</div>
                                                    </div>
                                                    <div className='flex justify-start items-center flex-row my-2 text-sm'>
                                                        <div className='font-semibold mr-2 w-[150px]'>{this.props.t("SingleSeller.Gender")} : </div>
                                                        <div>{this.state.seller_data.gender}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.seller_data.gst_status ?
                                                <div>
                                                    <div className='font-semibold text-sm m-4 w-[150px]'>{this.props.t("SingleSeller.GST Details")} : </div>
                                                    <div className='border overflow-x-scroll custom-scrollbar-horizontal2 m-4'>
                                                        <table className='w-full text-sm text-left text-gray-500'>
                                                            <thead className='text-xs whitespace-nowrap text-gray-700 uppercase bg-gray-50'>
                                                                <tr>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Business Name")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Registered Business Address")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Gst Number")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Business Type")}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_gst_details[0].business_name}
                                                                    </th>
                                                                    <td className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_gst_details[0].registered_business_address}
                                                                    </td>
                                                                    <td className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_gst_details[0].gst_number}
                                                                    </td>
                                                                    <td className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_gst_details[0].business_type}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                : <div className='flex items-center text-sm m-4'><div className='font-semibold w-[150px]'>{this.props.t("SingleSeller.GST Details")} : </div><div className=' w-[150px]'>{this.props.t("SingleSeller.Not Verified")}</div></div>}
                                            {this.state.seller_data.msme_status ?
                                                <div>
                                                    <div className='font-semibold text-sm m-4 w-[150px]'>{this.props.t("SingleSeller.MSME Details")} : </div>
                                                    <div className='border overflow-x-scroll custom-scrollbar-horizontal2 m-4'>
                                                        <table className='w-full text-sm text-left text-gray-500'>
                                                            <thead className='text-xs whitespace-nowrap text-gray-700 uppercase bg-gray-50'>
                                                                <tr>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Business Name")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Registered Business Address")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Gst Number")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Business Type")}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_msme_details[0].business_name}
                                                                    </th>
                                                                    <td className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_msme_details[0].registered_business_address}
                                                                    </td>
                                                                    <td className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_msme_details[0].gst_number}
                                                                    </td>
                                                                    <td className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_msme_details[0].business_type}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                : <div className='flex items-center text-sm m-4'><div className='font-semibold w-[150px]'>{this.props.t("SingleSeller.MSME Details")} : </div><div className=' w-[150px]'>{this.props.t("SingleSeller.Not Verified")}</div></div>}
                                            {this.state.seller_data.bank_details_status ?
                                                <div>
                                                    <div className='font-semibold text-sm m-4 w-[150px]'>{this.props.t("SingleSeller.Bank Details")} : </div>
                                                    <div className='border overflow-x-scroll custom-scrollbar-horizontal2 m-4'>
                                                        <table className='w-full text-sm text-left text-gray-500'>
                                                            <thead className='text-xs whitespace-nowrap text-gray-700 uppercase bg-gray-50'>
                                                                <tr>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Account Holder Name")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Bank Location")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Bank Name")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Account Number")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.IFSC Code")}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_bank_details[0].account_holder_name}
                                                                    </th>
                                                                    <td className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_bank_details[0].location}
                                                                    </td>
                                                                    <td className='px-6 whitespace-nowrap py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_bank_details[0].name}
                                                                    </td>
                                                                    <td className='px-6 whitespace-nowrap py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_bank_details[0].account_number}
                                                                    </td>
                                                                    <td className='px-6 py-2 md:py-4'>
                                                                        {this.state.seller_data.sellers_bank_details[0].ifsc_code}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                : <div className='flex items-center text-sm m-4'><div className='font-semibold w-[150px]'>{this.props.t("SingleSeller.Bank Details")} : </div><div className=' w-[150px]'>{this.props.t("SingleSeller.Not Verified")}</div></div>}
                                            {this.state.seller_data.billing_address_status ?
                                                <div>
                                                    <div className='font-semibold text-sm m-4 w-[150px]'>{this.props.t("SingleSeller.Billing Address")} : </div>
                                                    <div className='border overflow-x-scroll custom-scrollbar-horizontal2 m-4'>
                                                        <table className='w-full text-sm text-left text-gray-500'>
                                                            <thead className='text-xs whitespace-nowrap text-gray-700 uppercase bg-gray-50'>
                                                                <tr>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Flat/House/Building")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Area")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Landmark")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Town City")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.State")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Pincode")}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.seller_data.sellers_addresses.map(addr => addr.billing_status &&
                                                                    <tr key={addr.sellers_address_id}>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.flat_house_building}
                                                                        </td>
                                                                        <td className='px-6 whitespace-nowrap py-2 md:py-4'>
                                                                            {addr.area}
                                                                        </td>
                                                                        <td className='px-6 whitespace-nowrap py-2 md:py-4'>
                                                                            {addr.landmark}
                                                                        </td>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.town_city}
                                                                        </td>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.state}
                                                                        </td>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.pincode}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                : <div className='flex items-center text-sm m-4'><div className='font-semibold w-[150px]'>{this.props.t("SingleSeller.Billing Address")} : </div><div className=' w-[150px]'>{this.props.t("SingleSeller.Not Verified")}</div></div>}
                                            {this.state.seller_data.shipping_address_status ?
                                                <div>
                                                    <div className='font-semibold text-sm m-4 w-[150px]'>{this.props.t("SingleSeller.Shipping Address")} : </div>
                                                    <div className='border overflow-x-scroll custom-scrollbar-horizontal2 m-4'>
                                                        <table className='w-full text-sm text-left text-gray-500'>
                                                            <thead className='text-xs whitespace-nowrap text-gray-700 uppercase bg-gray-50'>
                                                                <tr>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Flat/House/Building")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Area")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Landmark")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Town City")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.State")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Pincode")}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.seller_data.sellers_addresses.map(addr => addr.shipping_status &&
                                                                    <tr key={addr.sellers_address_id}>
                                                                        <td className='px-6 py-4'>
                                                                            {addr.flat_house_building}
                                                                        </td>
                                                                        <td className='px-6 whitespace-nowrap py-2 md:py-4'>
                                                                            {addr.area}
                                                                        </td>
                                                                        <td className='px-6 whitespace-nowrap py-2 md:py-4'>
                                                                            {addr.landmark}
                                                                        </td>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.town_city}
                                                                        </td>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.state}
                                                                        </td>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.pincode}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                : <div className='flex items-center text-sm m-4'><div className='font-semibold w-[150px]'>{this.props.t("SingleSeller.Shipping Address")} : </div><div className=' w-[150px]'>{this.props.t("SingleSeller.Not Verified")}</div></div>}
                                            {this.state.seller_data.return_address_status ?
                                                <div>
                                                    <div className='font-semibold text-sm m-4 w-[150px]'>{this.props.t("SingleSeller.Return Address")} : </div>
                                                    <div className='border overflow-x-scroll custom-scrollbar-horizontal2 m-4'>
                                                        <table className='w-full text-sm text-left text-gray-500'>
                                                            <thead className='text-xs whitespace-nowrap text-gray-700 uppercase bg-gray-50'>
                                                                <tr>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Flat/House/Building")}
                                                                    </th>
                                                                    <th className='px-6 py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Area")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Landmark")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Town City")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.State")}
                                                                    </th>
                                                                    <th className='px-6 whitespace-nowrap py-2 md:py-3'>
                                                                        {this.props.t("SingleSeller.Pincode")}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.seller_data.sellers_addresses.map(addr => addr.return_status &&
                                                                    <tr key={addr.sellers_address_id}>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.flat_house_building}
                                                                        </td>
                                                                        <td className='px-6 whitespace-nowrap py-2 md:py-4'>
                                                                            {addr.area}
                                                                        </td>
                                                                        <td className='px-6 whitespace-nowrap py-2 md:py-4'>
                                                                            {addr.landmark}
                                                                        </td>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.town_city}
                                                                        </td>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.state}
                                                                        </td>
                                                                        <td className='px-6 py-2 md:py-4'>
                                                                            {addr.pincode}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                : <div className='flex items-center text-sm m-4'><div className='font-semibold w-[150px]'>{this.props.t("SingleSeller.Return Address")} : </div><div className=' w-[150px]'>{this.props.t("SingleSeller.Not Verified")}</div></div>}
                                            {
                                                !this.state.seller_data.signup_otp_status ? <div className='text-sm text-red-600'>{this.props.t("SingleSeller.Seller Details2")}. <Link className='text-blue-800' to={`/verify-seller/${this.state.seller_data.seller_id}`}>{this.props.t("SingleSeller.Verify Now")}</Link></div> : (!this.state.seller_data.gst_tax_status || !this.state.seller_data.signup_otp_status || !this.state.seller_data.bank_details_status || !this.state.seller_data.billing_address_status || !this.state.seller_data.shipping_address_status || !this.state.seller_data.return_address_status) &&
                                                    <div className='text-sm text-red-600'>{this.props.t("SingleSeller.Seller Details3")}. <Link className='text-blue-800' to={`/onboarding/${this.state.seller_data.seller_id}`}>{this.props.t("SingleSeller.Onboard Now")}</Link></div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='w-full mt-3 absolute bottom-0'>
                                <Footer />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        agent_info: state.agent_info,
        seller_data: state.seller_data
    };
};
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SingleSeller)));