import React, { Component } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';
import Profile from '../Components/Settings/Profile';
import ChangePassword from '../Components/Settings/ChangePassword';
import { withTranslation } from 'react-i18next';
import AccessDenied from '../Components/ProtectRoutes/AccessDenied';
import { connect } from 'react-redux';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_tab: null,
            breadcrumbs: [
                {
                    name: 'Settings',
                    link: '/settings'
                }
            ]
        }
    }
    render() {
        const selectTab = (tab) => {
            this.setState({ current_tab: tab });
            if (tab === "profile") {
                let breadcrumbs = [
                    {
                        name: 'Settings',
                        link: '/settings'
                    },
                    {
                        name: 'Profile Information',
                        link: '/settings'
                    },
                ]
                this.setState({
                    breadcrumbs: breadcrumbs
                })
            }
            else if (tab === "changePassword") {
                let breadcrumbs = [
                    {
                        name: 'Settings',
                        link: '/settings'
                    },
                    {
                        name: 'Change Password',
                        link: '/settings'
                    },
                ]
                this.setState({
                    breadcrumbs: breadcrumbs
                })
            }
        }
        return (
            <>
                {this.props.token ? (
                    <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                        <div className='w-fit h-fit z-50 fixed'>
                            <Sidebar />
                        </div>
                        <div className='w-full md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
                            <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                                <Navbar breadcrumbs={this.state.breadcrumbs} />
                            </div>
                            <div className='px-3 my-[60px] md:mt-[50px]'>
                                <div className='flex-shrink-0 flex-grow-0 flex flex-col overflow-y-auto overflow-x-hidden'>
                                    <div className='w-full rounded-md p-3 mt-3 block md:flex flex-row justify-center items-stretch gap-2'>
                                        <div className='flex flex-col justify-start items-stretch gap-2'>
                                            <div onClick={() => selectTab("profile")} className={`${this.state.current_tab === "profile" ? 'bg-[#fde465]' : 'bg-white'} p-3 rounded-md text-xs font-semibold cursor-pointer whitespace-nowrap text-center`}>{this.props.t("Settings.Profile Information")}</div>
                                            <div onClick={() => selectTab("changePassword")} className={`${this.state.current_tab === "changePassword" ? 'bg-[#fde465]' : 'bg-white'} p-3 rounded-md text-xs font-semibold mb-3 md:mb-0 cursor-pointer whitespace-nowrap text-center`}>{this.props.t("Settings.Change Password")}</div>
                                        </div>
                                        <div className='bg-white w-full flex-shrink rounded-md'>
                                            {
                                                this.state.current_tab === null && <div className='text-gray-500 text-sm p-3'>{this.props.t("Settings.tab")}</div>
                                            }
                                            {
                                                this.state.current_tab === "profile" && <Profile />
                                            }
                                            {
                                                this.state.current_tab === "changePassword" && <ChangePassword />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full mt-3 absolute bottom-0'>
                                <Footer />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
export default connect(mapStateToProps, null)(withTranslation()(Settings));