import React, { Component } from 'react'
import HomePage from './Pages/HomePage'
import Signin from './Pages/Signin'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ScrollToTop from './Components/ScrollToTop'
import Dashboard from './Pages/Dashboard'
import ForgetPassword from './Pages/ForgetPassword'
import AddSeller from './Pages/AddSeller'
import Sellers from './Pages/Sellers'
import Settings from './Pages/Settings'
import { getAgentInfo, getSellers, setLogin } from './actions'
import { connect } from 'react-redux'
import Onboarding from './Pages/Onboarding'
import SingleSeller from './Pages/SingleSeller'
import SignUpVerify from './Pages/SignUpVerify'
import Terms from './Pages/Terms'
import Policy from './Pages/Policy'
import NotFound from './Components/ProtectRoutes/error404'
import Docs from './Pages/Docs'
import Dash from './Components/Docs/Dash'
import ManageSeller from './Components/Docs/ManageSeller'
import SellerAdd from './Components/Docs/SellerAdd'
import DocSettings from './Components/Docs/DocSettings'

class AppRoutes extends Component {
    componentDidMount() {
        let dukkandaar_seller_hub = window.localStorage.getItem("dukkandaar_seller_hub");
        if (dukkandaar_seller_hub) {
            dukkandaar_seller_hub = JSON.parse(dukkandaar_seller_hub);
            let token = dukkandaar_seller_hub.token;
            if (token) {
                this.props.loginDispatch(token);
            }
        }
    }
    componentDidUpdate() {
        if (this.props.token && !this.props.agent_info) {
            this.props.agentInfoDispatch(this.props.token);
            this.props.getSellersDispatch({ token: this.props.token, page: 1 });
        }
    }
    render() {
        return (
            <BrowserRouter>
                <ScrollToTop>
                    <Routes>
                        <Route exact path="/" element={<HomePage />} />
                        <Route exact path="/signin" element={<Signin />} />
                        <Route exact path="/dashboard" element={<Dashboard />} />
                        <Route exact path="/reset-password" element={<ForgetPassword />} />
                        <Route exact path="/terms-condition" element={<Terms />} />
                        <Route exact path="/policy" element={<Policy />} />
                        <Route exact path="/add-seller" element={<AddSeller />} />
                        <Route exact path="/sellers" element={<Sellers />} />
                        <Route exact path="/settings" element={<Settings />} />
                        <Route exact path="/docs" element={<Docs />} />
                        <Route exact path="/docs/Dash" element={<Dash />} />
                        <Route exact path="/docs/ManageSeller" element={<ManageSeller />} />
                        <Route exact path="/docs/addseller" element={<SellerAdd />} />
                        <Route exact path="/docs/settings" element={<DocSettings />} />
                        <Route exact path="/onboarding/:seller_id" element={<Onboarding />} />
                        <Route exact path="/seller-details/:id" element={<SingleSeller />} />
                        <Route exact path="/verify-seller/:id" element={<SignUpVerify />} />
                        <Route exact path="*" element={<NotFound />} />
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const loginDispatch = (token) => {
        dispatch(setLogin(token));
    };
    const agentInfoDispatch = (token) => {
        dispatch(getAgentInfo(token));
    };
    const getSellersDispatch = (data) => {
        dispatch(getSellers(data.token, data.page, null, null, null, null, null));
    }
    return {
        loginDispatch,
        getSellersDispatch,
        agentInfoDispatch
    };
};
const mapStateToProps = (state) => {
    return {
        token: state.token,
        agent_info: state.agent_info
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);