import React, { Component } from 'react'
import logo from '../Assets/logo.png'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class HomeBar extends Component {
    constructor(props) {
        super(props);
        const storedOption = localStorage.getItem('selectedLanguage');
        this.state = {
            selectedLanguage: storedOption ? storedOption : 'en',
        };
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }
    handleLanguageChange = (event) => {
        const { i18n } = this.props;
        i18n.changeLanguage(event.target.value);
        const selectedLanguage = event.target.value;
        this.setState({ selectedLanguage });
        localStorage.setItem('selectedLanguage', selectedLanguage);
      };
    render() {
        return (
            <div className='w-full py-3 bg-gray-200 fixed top-0 left-0 z-50'>
                <div className='flex flex-row justify-between items-center max-w-[900px] px-5 m-auto'>
                    <div>
                        <img src={logo} alt="Dukkandaar-Logo" className='w-[100px]' />
                    </div>
                    <div className='gap-3 flex flex-row justify-end items-center'>
                        <select name="langauge" id="language" className='text-sm px-2 bg-gray-200' value={this.state.selectedLanguage} onChange={this.handleLanguageChange}>
                            <option value="en" className='text-sm px-2'>English - EN</option>
                            <option value="hi" className='text-sm px-2'>हिन्दी - HI</option>
                            <option value="pa" className='text-sm px-2'>ਪੰਜਾਬੀ - PA</option>
                        </select>
                        <Link to={'/signin'}>
                            <div className='text-sm font-semibold cursor-pointer'>{this.props.t("Sign In")}</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(HomeBar);