import React, { Component } from 'react'
import logo from '../Assets/logo-light.png'
import { BiSolidDashboard } from 'react-icons/bi'
import { BsPersonFillAdd } from 'react-icons/bs'
import { MdPeopleAlt, MdOutlineSettingsSuggest, MdLogout } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../actions'
import { withTranslation } from 'react-i18next'

const withRouter = WrappedComponent => props => {
    const location = useLocation();
    return (
        <WrappedComponent
            {...props}
            location={location}
        />
    );
};

class Sidebar extends Component {
    render() {
        const { pathname } = this.props.location;
        const logout = () => {
            this.props.logoutDispatch();
        }
        return (
            <div className='w-[250px] h-screen bg-[#041737] py-4 px-8 text-white md:block hidden overflow-auto custom-scrollbar-sidebar'>
                <img src={logo} className='w-[100px] m-auto mb-10' alt="" />
                <div>
                    <div className='mb-1'>
                        <Link to={'/dashboard'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/dashboard" && 'bg-[#3b62ad] text-white'} duration-300 ease-in-out text-gray-400`}>
                            <BiSolidDashboard size={20} />
                            <div className={`w-full flex-shrink ml-3 ${pathname === "/dashboard" && 'text-white'} font-medium text-sm`}>{this.props.t("Sidebar.Dashboard")}</div>
                        </Link>
                    </div>
                    <div className='text-xs text-[#446ea9] mt-4 mb-1'>{this.props.t("Sidebar.Seller Management")}</div>
                    <div className='mb-1'>
                        <Link to={'/sellers'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/sellers" && 'bg-[#3b62ad] text-white'} duration-300 ease-in-out text-gray-400`}>
                            <MdPeopleAlt size={20} />
                            <div className='w-full flex-shrink ml-3 font-medium text-sm'>{this.props.t("Sidebar.Manage Sellers")}</div>
                        </Link>
                    </div>
                    <div className='mb-1'>
                        <Link to={'/add-seller'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/add-seller" && 'bg-[#3b62ad] text-white'} duration-300 ease-in-out text-gray-400`}>
                            <BsPersonFillAdd size={20} />
                            <div className='w-full flex-shrink ml-3 font-medium text-sm'>{this.props.t("Sidebar.Add Seller")}</div>
                        </Link>
                    </div>
                    <div className='text-xs text-[#446ea9] mt-4 mb-1'>{this.props.t("Sidebar.General")}</div>
                    <div className='mb-1'>
                        <Link to={'/settings'} className={`flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] ${pathname === "/settings" && 'bg-[#3b62ad] text-white'} duration-300 ease-in-out text-gray-400`}>
                            <MdOutlineSettingsSuggest size={20} />
                            <div className='w-full flex-shrink ml-3 font-medium text-sm'>{this.props.t("Sidebar.Settings")}</div>
                        </Link>
                    </div>
                    <div className='mb-1'>
                        {this.props.token ?
                            <div className='flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] duration-300 ease-in-out text-gray-400'>
                                <MdLogout size={20} />
                                <Link to={"/"}><div onClick={() => logout()} className='w-full flex-shrink ml-3 font-medium text-sm'>{this.props.t("Sidebar.Logout")}</div></Link>
                            </div>
                            :
                            <div className='flex justify-start items-center cursor-pointer py-2 px-4 rounded-md hover:text-white hover:bg-[#3b62ad] duration-300 ease-in-out text-gray-400'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                </svg>

                                <Link to={"/signin"}><div className='w-full flex-shrink ml-3 font-medium text-sm'>{this.props.t("Sign In")}</div></Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};

const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Sidebar)));