import React, { Component } from 'react'
import AppRoutes from './AppRoutes'
import './App.css'
import { connect } from 'react-redux'
import { logout, setLogin } from './actions'

class App extends Component {
  render() {
    return (
      <AppRoutes />
    )
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    agent_info: state.agent_info
  };
};
export default connect(mapStateToProps, null)(App);