import React, { Component } from 'react'
import SideDocs from '../SideDocs'
import Footer from '../Footer'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AccessDenied from '../ProtectRoutes/AccessDenied';
import { MdPeopleAlt } from 'react-icons/md';
import NavbarDocs from './NavbarDocs';

class SellerAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_tab: null,
      breadcrumbs: [
        {
          name: 'Docs',
          link: '/docs'
        },
        {
          name: 'AddSeller',
          link: '/docs/AddSeller'
        }
      ]
    }
  }
  render() {
    return (
      <>
        {this.props.token ? (
          <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
            <div className='w-fit h-fit z-50 fixed'>
              <SideDocs />
            </div>
            <div className='w-full md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
              <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                <NavbarDocs breadcrumbs={this.state.breadcrumbs} />
              </div>
              <div className='px-3 my-[60px] md:mt-[50px]'>
                <div className='flex-shrink-0 flex-grow-0 flex flex-col overflow-y-auto overflow-x-hidden'>
                  <div className='w-full rounded-md p-3 mt-3 block md:flex flex-row justify-center items-stretch gap-2'>
                    <div className="w-full text-xs md:text-base md:p-6">
                      <div className="w-full">
                        <h1 className="text-2xl font-medium">{this.props.t("docs.Documentation")}</h1>
                      </div>
                      <div className="w-full mt-8">
                        <h2 className="text-lg font-medium flex items-center gap-2 bg-slate-50 border border-slate-300 p-2 rounded mt-4">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                          </svg>
                          {this.props.t("docs.AddSeller")}
                        </h2>
                        <article className="mt-2 text-justify">{this.props.t("docs.AddSeller1")}</article>

                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <MdPeopleAlt size={20} />
                            {this.props.t("docs.AddSeller2")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller3")}</span>{this.props.t("docs.AddSeller4")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller5")}</span>{this.props.t("docs.AddSeller6")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller7")}</span>{this.props.t("docs.AddSeller8")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller9")}</span>{this.props.t("docs.AddSeller10")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller11")}</span>{this.props.t("docs.AddSeller12")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller13")}</span>{this.props.t("docs.AddSeller14")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller15")}</span>{this.props.t("docs.AddSeller16")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller17")}</span>{this.props.t("docs.AddSeller18")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller19")}</span>{this.props.t("docs.AddSeller20")}</li>
                          </ul>
                        </div>
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <MdPeopleAlt size={20} />
                            {this.props.t("docs.AddSeller21")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller22")}</span>{this.props.t("docs.AddSeller23")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller24")}</span>{this.props.t("docs.AddSeller25")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller26")}</span>{this.props.t("docs.AddSeller27")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller28")}</span>{this.props.t("docs.AddSeller29")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller30")}</span>{this.props.t("docs.AddSeller31")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller32")}</span>{this.props.t("docs.AddSeller33")}</li>
                          </ul>
                          <article className="mt-2 text-justify">{this.props.t("docs.AddSeller34")}</article>
                        </div>
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <MdPeopleAlt size={20} />
                            {this.props.t("docs.AddSeller35")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller36")}</span>{this.props.t("docs.AddSeller37")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller38")}</span>{this.props.t("docs.AddSeller39")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller40")}</span>{this.props.t("docs.AddSeller41")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller42")}</span>{this.props.t("docs.AddSeller43")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller44")}</span>{this.props.t("docs.AddSeller45")}</li>
                          </ul>
                          <article className="mt-2 text-justify">{this.props.t("docs.AddSeller46")}</article>
                        </div>
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <MdPeopleAlt size={20} />
                            {this.props.t("docs.AddSeller47")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller48")}</span>{this.props.t("docs.AddSeller49")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller50")}</span>{this.props.t("docs.AddSeller51")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller52")}</span></li>
                            <ul className="mt-2 list-disc list-outside text-justify ml-4">
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller53")}</span>{this.props.t("docs.AddSeller54")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller55")}</span>{this.props.t("docs.AddSeller56")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller57")}</span>{this.props.t("docs.AddSeller58")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller59")}</span>{this.props.t("docs.AddSeller60")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller61")}</span>{this.props.t("docs.AddSeller62")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller63")}</span>{this.props.t("docs.AddSeller64")}</li>
                            </ul>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller65")}</span>{this.props.t("docs.AddSeller66")}</li>
                          </ul>
                          <article className="mt-2 text-justify">{this.props.t("docs.AddSeller67")}</article>
                        </div>
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <MdPeopleAlt size={20} />
                            {this.props.t("docs.AddSeller68")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller69")}</span>{this.props.t("docs.AddSeller70")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller71")}</span>{this.props.t("docs.AddSeller72")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller73")}</span></li>
                            <ul className="mt-2 list-disc list-outside text-justify ml-4">
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller74")}</span>{this.props.t("docs.AddSeller75")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller76")}</span>{this.props.t("docs.AddSeller77")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller78")}</span>{this.props.t("docs.AddSeller79")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller80")}</span>{this.props.t("docs.AddSeller81")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller82")}</span>{this.props.t("docs.AddSeller83")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller84")}</span>{this.props.t("docs.AddSeller85")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller86")}</span>{this.props.t("docs.AddSeller87")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller88")}</span>{this.props.t("docs.AddSeller89")}</li>
                            </ul>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller90")}</span>{this.props.t("docs.AddSeller91")}</li>
                          </ul>
                          <article className="mt-2 text-justify">{this.props.t("docs.AddSeller92")}</article>
                        </div>
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <MdPeopleAlt size={20} />
                            {this.props.t("docs.AddSeller93")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller94")}</span>{this.props.t("docs.AddSeller95")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller96")}</span>{this.props.t("docs.AddSeller97")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller98")}</span></li>
                            <ul className="mt-2 list-disc list-outside text-justify ml-4">
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller99")}</span>{this.props.t("docs.AddSeller100")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller101")}</span>{this.props.t("docs.AddSeller102")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller103")}</span>{this.props.t("docs.AddSeller104")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller105")}</span>{this.props.t("docs.AddSeller106")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller107")}</span>{this.props.t("docs.AddSeller108")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller109")}</span>{this.props.t("docs.AddSeller110")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller111")}</span>{this.props.t("docs.AddSeller112")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller113")}</span>{this.props.t("docs.AddSeller114")}</li>
                            </ul>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller115")}</span>{this.props.t("docs.AddSeller116")}</li>
                          </ul>
                          <article className="mt-2 text-justify">{this.props.t("docs.AddSeller117")}</article>
                        </div>
                        <div className="w-full mt-3 border-t border-gray-300 py-3">
                          <h3 className="text-lg font-medium flex items-center gap-2">
                            <MdPeopleAlt size={20} />
                            {this.props.t("docs.AddSeller118")}
                          </h3>
                          <ul className="mt-2 list-disc list-outside text-justify ml-8">
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller119")}</span>{this.props.t("docs.AddSeller120")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller121")}</span>{this.props.t("docs.AddSeller122")}</li>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller123")}</span></li>
                            <ul className="mt-2 list-disc list-outside text-justify ml-4">
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller124")}</span>{this.props.t("docs.AddSeller125")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller126")}</span>{this.props.t("docs.AddSeller127")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller128")}</span>{this.props.t("docs.AddSeller129")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller130")}</span>{this.props.t("docs.AddSeller131")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller132")}</span>{this.props.t("docs.AddSeller133")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller134")}</span>{this.props.t("docs.AddSeller135")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller136")}</span>{this.props.t("docs.AddSeller137")}</li>
                              <li><span className='font-bold'>{this.props.t("docs.AddSeller138")}</span>{this.props.t("docs.AddSeller139")}</li>
                            </ul>
                            <li><span className='font-bold'>{this.props.t("docs.AddSeller140")}</span>{this.props.t("docs.AddSeller141")}</li>
                          </ul>
                          <article className="mt-2 text-justify">{this.props.t("docs.AddSeller142")}</article>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full mt-3 absolute bottom-0'>
                <Footer />
              </div>
            </div>
          </div>
        ) : (
          <div><AccessDenied /></div>
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(withTranslation()(SellerAdd));