import React, { Component } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { logout } from '../actions';
import AccessDenied from '../Components/ProtectRoutes/AccessDenied';
const withRouter = WrappedComponent => props => {
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            params={params}
        />
    );
};


class SignUpVerify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verifyButtonDisabled: false,
            sendButtonDisabled: false,
            navigateTo: null,
            seller_data: null,
            otpSent: false,
            otpd1: "",
            otpd2: "",
            otpd3: "",
            otpd4: "",
            otpd5: "",
            otpd6: ""
        }
        this.otp1ref = React.createRef();
        this.otp2ref = React.createRef();
        this.otp3ref = React.createRef();
        this.otp4ref = React.createRef();
        this.otp5ref = React.createRef();
        this.otp6ref = React.createRef();
    }
    componentDidMount() {
        const { id } = this.props.params;
        var myHeaders = new Headers();
        myHeaders.append("token", this.props.token);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_URL}/get_single_sellers/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 401 || result.code === 407) {
                    this.props.logoutDispatch();
                    toast.error(result.msg);
                }
                else {
                    console.log(result);
                    if (result.status === "Success" || result.status === "success") {
                        this.setState({ seller_data: result.seller_data })
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
    componentDidUpdate() {
        if (!this.state.seller_data && this.props.token) {
            const { id } = this.props.params;
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/get_single_sellers/${id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        console.log(result);
                        if (result.status === "Success" || result.status === "success") {
                            this.setState({ seller_data: result.seller_data })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    render() {
        const verifyOTP = () => {
            let otp = `${this.state.otpd1}${this.state.otpd2}${this.state.otpd3}${this.state.otpd4}${this.state.otpd5}${this.state.otpd6}`;
            if (otp.length !== 6) {
                toast.error("Incorrect OTP!")
            }
            else {
                otp = (Number)(otp);
                this.setState({ verifyButtonDisabled: true });
                var myHeaders = new Headers();
                myHeaders.append("token", this.props.token);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    number: this.state.seller_data.number,
                    OTP: otp
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_API_URL}/signup_verify_otp`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 401 || result.code === 407) {
                            this.props.logoutDispatch();

                        }
                        else {
                            console.log(result);
                            if (result.status === "Success" || result.status === "success") {
                                toast.success("Seller Verified !")
                                this.setState({ navigateTo: `/onboarding/${this.state.seller_id}` })
                            } else {
                                toast.error(result.msg)
                            }
                            this.setState({ verifyButtonDisabled: false });
                        }
                    })
                    .catch((error) => {
                        toast.error("Server Error")
                    });
            }
        }
        const sendOTP = () => {
            this.setState({ sendButtonDisabled: true })
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "number": this.state.seller_data.number
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/signup_send_otp`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        console.log(result);
                        if (result.status === 'Success' || result.status === 'success') {
                            toast.success(result.msg)
                            this.setState({ otpSent: true, sendButtonDisabled: false });
                        } else {
                            toast.error(result.msg)
                        }
                        this.setState({ sendButtonDisabled: false })
                    }
                })
                .catch((error) => {
                    toast.error("Server Error")
                });
        }
        const resendOTP = () => {
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "number": this.state.seller_data.number
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_API_URL}/signup_send_otp`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            toast.success(result.msg);
                        }
                        else {
                            toast.error(result.msg);
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
        let breadcrumbs = [
            {
                name: 'Sellers',
                link: '/sellers'
            },
            {
                name: 'Add New Seller',
                link: '/add-seller'
            },
            {
                name: 'Verify OTP',
                link: '/add-seller'
            },
        ]
        return (
            <>
                {this.props.token ? (
            <div className='flex flex-row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                <div className='w-fit h-fit z-50 fixed'>
                    <Sidebar />
                </div>
                <div className='w-full md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
                    <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                        <Navbar breadcrumbs={breadcrumbs} />
                    </div>
                    <div className='px-3 max-w-[500px] my-[80px] md:my-[50px]'>
                        <div className='w-full p-3 bg-white rounded-md'>
                            <div className='text-lg font-semibold'>{this.props.t("SignUpVerify.Verify OTP")}</div>
                            <div className='text-xs text-gray-400 mb-2'>{this.props.t("SignUpVerify.Verify OTP1")}.</div>
                            <div className='text-xs my-2'>{this.props.t("SignUpVerify.Shop Name")} : {this.state.seller_data?.shop_name}</div>
                            <div className='text-xs my-2'>{this.props.t("SignUpVerify.Seller Name")} : {this.state.seller_data?.seller_name}</div>
                            <div className='text-xs mb-6'>{this.props.t("SignUpVerify.Mobile Number")} : {this.state.seller_data?.number}</div>
                            {
                                !this.state.otpSent &&
                                <div className='my-6'>
                                    <button
                                        type="submit"
                                        className={`bg-[#FFD814]  text-center py-1 px-8 text-sm font-semibold rounded-full shadow-md ${this.state.sendButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                                        onClick={() => sendOTP()}
                                        disabled={this.state.sendButtonDisabled}
                                    >
                                        <div className={`${!this.state.sendButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                {this.props.t("Sent OTP")}
                                        </div>
                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.sendButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            <ThreeDots
                                                height="32"
                                                width="30"
                                                radius="9"
                                                color="#000"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    </button>
                                </div>
                            }
                            {
                                this.state.otpSent &&
                                <>
                                    <div className='my-6 flex flex-col justify-center items-start'>
                                        <div className='text-xs font-semibold text-black cursor-pointer'>{this.props.t("SignUpVerify.OTP Received")}</div>
                                        <div className='w-full max-w-[320px] flex justify-start items-center gap-3 mt-2'>
                                            <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp1ref} value={this.state.otpd1} onChange={(e) => {
                                                if (e.target.value.length === 1) {
                                                    this.setState({ otpd1: e.target.value });
                                                    this.otp2ref.current.focus();
                                                }
                                                if (e.target.value.length === 0) {
                                                    this.setState({ otpd1: e.target.value });
                                                }
                                            }} />
                                            <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp2ref} value={this.state.otpd2} onChange={(e) => {
                                                if (e.target.value.length === 1) {
                                                    this.setState({ otpd2: e.target.value });
                                                    this.otp3ref.current.focus();
                                                }
                                                if (e.target.value.length === 0) {
                                                    this.setState({ otpd2: e.target.value });
                                                    this.otp1ref.current.focus();
                                                }
                                            }} />
                                            <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp3ref} value={this.state.otpd3} onChange={(e) => {
                                                if (e.target.value.length === 1) {
                                                    this.setState({ otpd3: e.target.value });
                                                    this.otp4ref.current.focus();
                                                }
                                                if (e.target.value.length === 0) {
                                                    this.setState({ otpd3: e.target.value });
                                                    this.otp2ref.current.focus();
                                                }
                                            }} />
                                            <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp4ref} value={this.state.otpd4} onChange={(e) => {
                                                if (e.target.value.length === 1) {
                                                    this.setState({ otpd4: e.target.value });
                                                    this.otp5ref.current.focus();
                                                }
                                                if (e.target.value.length === 0) {
                                                    this.setState({ otpd4: e.target.value });
                                                    this.otp3ref.current.focus();
                                                }
                                            }} />
                                            <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp5ref} value={this.state.otpd5} onChange={(e) => {
                                                if (e.target.value.length === 1) {
                                                    this.setState({ otpd5: e.target.value });
                                                    this.otp6ref.current.focus();
                                                }
                                                if (e.target.value.length === 0) {
                                                    this.setState({ otpd5: e.target.value });
                                                    this.otp4ref.current.focus();
                                                }
                                            }} />
                                            <input type="number" className='aspect-square w-full flex-shrink rounded-md border border-gray-500 text-center focus:outline-none' ref={this.otp6ref} value={this.state.otpd6} onChange={(e) => {
                                                if (e.target.value.length === 1) {
                                                    this.setState({ otpd6: e.target.value });
                                                }
                                                if (e.target.value.length === 0) {
                                                    this.setState({ otpd6: e.target.value });
                                                    this.otp5ref.current.focus();
                                                }
                                            }} />
                                        </div>
                                    </div>
                                    <div className='my-6 flex flex-row justify-start items-center'>
                                        <div className='text-xs font-semibold text-[#5aa3b0] cursor-pointer' onClick={() => resendOTP()}>{this.props.t("SignUpVerify.Resend OTP")}</div>
                                    </div>
                                    <div className='my-6'>
                                        <button
                                            type="submit"
                                            className={`bg-[#FFD814]  text-center py-1 px-8 text-sm font-semibold rounded-full shadow-md ${this.state.verifyButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                                            onClick={() => verifyOTP()}
                                            disabled={this.state.verifyButtonDisabled}
                                        >
                                            <div className={`${!this.state.verifyButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                {this.props.t("SignUpVerify.Verify OTP")}
                                            </div>
                                            <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.verifyButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                <ThreeDots
                                                    height="32"
                                                    width="30"
                                                    radius="9"
                                                    color="#000"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </div>
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {
                        this.state.navigateTo && <Navigate to={this.state.navigateTo} />
                    }
                    <div className='w-full mt-3 absolute bottom-0'>
                        <Footer />
                    </div>
                </div>
            </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};
const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SignUpVerify)));