import React, { Component } from 'react'
import Accessdenied from '../../Assets/access-denied.jpg'
import { Link } from 'react-router-dom'

export default class AccessDenied extends Component {
    render() {
        return (
            <div>
                <div className="flex justify-center items-center h-screen  bg-gray-100 text-gray-700">
                    <div className="p-8 rounded-lg shadow md:w-6/12 flex bg-white">
                        <div className="md:w-7/12 w-6/12">
                            <img src={Accessdenied} alt="" />
                        </div>
                        <div className="md:w-5/12 w-6/12 flex flex-col justify-center">
                            <h1 className="md:text-3xl text-base font-bold mb-4">Access Denied</h1>
                            <p className="text-gray-400 md:text-base text-xs">Please SignIn to Access this resource.</p>
                            <p className="text-gray-400 md:text-base text-xs">If you are not sign in, please click here</p>
                            <Link className="w-[100px] inline-flex justify-center font-semibold mt-3 bg-indigo-500 p-1 rounded px-4 text-white" to={'/signin'}>Sign In</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
