import React, { Component } from 'react'
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';

class BillingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            state: '',
            town_city: '',
            flat_house_building: '',
            area: '',
            landmark: '',
            pincode: '',
            address_type: '',
            billing_default_address: false

        }
    }
    sendDataToParent = () => {
        if (this.state.state === "") {
            toast.error("Please enter State Name");
        }
        else if (this.state.town_city === "") {
            toast.error("Please enter Town/City");
        }
        else if (this.state.flat_house_building === "") {
            toast.error("Please enter your Flat/House/Building");
        }
        else if (this.state.area === "") {
            toast.error("Please enter your Area");
        }
        else if (this.state.landmark === "") {
            toast.error("Please enter your Landmark");
        }
        else if (this.state.pincode === "") {
            toast.error("Please enter a pincode");
        }
        else if (this.state.address_type === "") {
            toast.error("Please enter your Address Type");
        }
        else {
            this.setState({ submitButtonDisbled: true });
            const data = {
                state: this.state.state,
                town_city: this.state.town_city,
                flat_house_building: this.state.flat_house_building,
                area: this.state.area,
                landmark: this.state.landmark,
                pincode: this.state.pincode,
                address_type: this.state.address_type,
                billing_default_address: this.state.billing_default_address
            };
            console.log('Calling sendDataToParent', data);
            this.props.verifyBillingDetails(data);
        }
    };
    render() {
        return (
            <div className='w-full p-3 justify-center bg-white rounded-md'>
                <div className='text-lg font-semibold'>{this.props.t("BillingForm.Verify Billing Details")}</div>
                <div className='text-xs text-gray-400 mb-6'>{this.props.t("BillingForm.Verify Billing Details1")}. </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BillingForm.State")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BillingForm.Town/City")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.town_city} onChange={(e) => this.setState({ town_city: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BillingForm.Flat/House/Building")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.flat_house_building} onChange={(e) => this.setState({ flat_house_building: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BillingForm.Area")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.area} onChange={(e) => this.setState({ area: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BillingForm.Landmark")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.landmark} onChange={(e) => this.setState({ landmark: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BillingForm.pincode")}</div>
                    <input type="pincode" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.pincode} onChange={(e) => this.setState({ pincode: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BillingForm.Address Type")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.address_type} onChange={(e) => this.setState({ address_type: e.target.value })} />
                </div>
                <div className='my-3 flex flex-row  items-center'>
                    <input type="checkbox" name="termsacept" id="termsaccept"
                        checked={this.state.billing_default_address}
                        onChange={(e) => this.setState({ billing_default_address: e.target.checked })} />
                    <div className='text-xs font-semibold text-black ml-2'>{this.props.t("BillingForm.Billing Default Address")}</div>
                </div>
                <div className='my-3'>
                    <button
                        type="submit"
                        className={`w-fit bg-[#ffd814] text-black font-semibold py-1 px-8 text-center rounded-full shadow-md text-sm ${this.props.submitButtonDisbled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                        onClick={() => this.sendDataToParent()}
                        disabled={this.props.submitButtonDisbled}
                    >
                        <div className={`${!this.props.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                        {this.props.t("Submit")}
                        </div>
                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.props.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            <ThreeDots
                                height="32"
                                width="30"
                                radius="9"
                                color="#000"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    </button>
                </div>
            </div>
        )
    }
}

export default withTranslation()(BillingForm);