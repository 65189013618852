import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { BiSolidDashboard } from 'react-icons/bi';
import { BsPersonFillAdd } from 'react-icons/bs';
import { MdOutlineSettingsSuggest, MdPeopleAlt } from 'react-icons/md';

class Documentation extends Component {
    render() {
        return (
            <div className="w-full text-xs md:text-base md:p-6">
                <div className="w-full">
                    <h1 className="text-2xl font-medium">{this.props.t("docs.Documentation")}</h1>
                </div>
                <div className="w-full mt-8">
                    <h2 className="text-lg font-medium flex items-center gap-2 bg-slate-50 border border-slate-300 p-2 rounded mt-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                        </svg>
                        {this.props.t("docs.Introduction")}
                    </h2>
                    <article className="mt-2 text-justify">{this.props.t("docs.Introduction1")}</article>

                    <div className="w-full mt-3 border-t border-gray-300 py-3">
                        <h3 className="text-lg font-medium flex items-center gap-2">
                            <BiSolidDashboard size={20} />
                            {this.props.t("docs.Dashboard")}
                        </h3>
                        <article className="mt-2 text-justify">{this.props.t("docs.Dashboard1")}</article>
                    </div>
                    <div className="w-full mt-3 border-t border-gray-300 py-3">
                        <h3 className="text-lg font-medium flex items-center gap-2">
                            <MdPeopleAlt size={20} />
                            {this.props.t("docs.Manage Seller")}
                        </h3>
                        <article className="mt-2 text-justify">{this.props.t("docs.Manage Seller1")}</article>
                    </div>
                    <div className="w-full mt-3 border-t border-gray-300 py-3">
                        <h3 className="text-lg font-medium flex items-center gap-2">
                            <BsPersonFillAdd size={20} />
                            {this.props.t("docs.Add Seller")}
                        </h3>
                        <article className="mt-2 text-justify">{this.props.t("docs.Add Seller1")}</article>
                    </div>
                    <div className="w-full mt-3 border-t border-gray-300 py-3">
                        <h3 className="text-lg font-medium flex items-center gap-2">
                            <MdOutlineSettingsSuggest size={20} />
                            {this.props.t("docs.Settings")}
                        </h3>
                        <article className="mt-2 text-justify">{this.props.t("docs.Settings1")}</article>
                    </div>
                    <div className="w-full mt-3 border-t border-gray-300 py-3">
                        <h3 className="text-lg font-medium flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                            </svg>
                            {this.props.t("docs.Conclusion")}
                        </h3>
                        <article className="mt-2 text-justify">{this.props.t("docs.Conclusion1")}</article>
                        <article className="mt-2 text-justify">{this.props.t("docs.Conclusion2")}</article>
                        <article className="mt-2 text-justify">{this.props.t("docs.Conclusion3")}</article>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Documentation);