import React, { Component } from 'react'
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';

class BankingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            account_holder_name: "",
            account_number: "",
            ifsc_code: "",
            location: "",
            currency: ""
        }
    }
    sendDataToParent = () => {
        if (this.state.name === "") {
            toast.error("Please enter Bank Name");
        }
        else if (this.state.account_holder_name === "") {
            toast.error("Please enter Account Holder Name");
        }
        else if (this.state.account_number === "") {
            toast.error("Please enter your Account Number");
        }
        else if (this.state.ifsc_code === "") {
            toast.error("Please enter your IFSC Code");
        }
        else if (this.state.location === "") {
            toast.error("Please enter your Location");
        }
        else if (this.state.currency === "") {
            toast.error("Please enter a Currency");
        }
        else {
            this.setState({ submitButtonDisbled: true });
            const data = {
                name: this.state.name,
                account_holder_name: this.state.account_holder_name,
                account_number: this.state.account_number,
                ifsc_code: this.state.ifsc_code,
                location: this.state.location,
                currency: this.state.currency
            };
            // console.log('Calling sendDataToParent', data);
            this.props.verifyBankDetails(data);
        }
    };
    render() {
        return (
            <div className='w-full p-3 justify-center bg-white rounded-md'>
                <div className='text-lg font-semibold'>{this.props.t("BankingForm.Verify Banking Details")}</div>
                <div className='text-xs text-gray-400 mb-6'>{this.props.t("BankingForm.Verify Banking Details1")}. </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BankingForm.Bank Name")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BankingForm.Account Holder Name")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.account_holder_name} onChange={(e) => this.setState({ account_holder_name: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BankingForm.Account Number")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.account_number} onChange={(e) => this.setState({ account_number: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BankingForm.IFSC code")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.ifsc_code} onChange={(e) => this.setState({ ifsc_code: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BankingForm.Location")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.location} onChange={(e) => this.setState({ location: e.target.value })} />
                </div>
                <div className='my-3 flex flex-col justify-center items-start'>
                    <div className='text-xs font-semibold text-black'>{this.props.t("BankingForm.Currency")}</div>
                    <input type="text" className='w-full max-w-[400px] border-[1.5px] border-gray-300 rounded-md mt-1 focus:outline-none py-2 px-2 text-xs' placeholder='' value={this.state.currency} onChange={(e) => this.setState({ currency: e.target.value })} />
                </div>
                <div className='my-3'>
                    <button
                        type="submit"
                        className={`w-fit bg-[#ffd814] text-black font-semibold py-1 px-8 text-center rounded-full shadow-md text-sm ${this.props.submitButtonDisbled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                        onClick={() => this.sendDataToParent()}
                        disabled={this.props.submitButtonDisbled}
                    >
                        <div className={`${!this.props.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                        {this.props.t("Submit")}
                        </div>
                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.props.submitButtonDisbled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                            <ThreeDots
                                height="32"
                                width="30"
                                radius="9"
                                color="#000"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    </button>
                </div>
            </div>
        )
    }
}
export default withTranslation()(BankingForm);