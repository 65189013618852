import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import { Link } from 'react-router-dom';

class Policy extends Component {
    render() {
        let breadcrumbs = [
            {
                name: 'Policy',
                link: '/policy'
            }
        ]
        return (
            <div className=' -row justify-start items-start w-full min-h-screen bg-[#ededed]'>
                <div className='w-fit h-fit z-50 fixed'>
                    <Sidebar />
                </div>
                <div className='w-full md:w-[calc(100%-250px)] ml-auto min-h-screen relative overflow-x-hidden'>
                    <div className='fixed top-0 right-0 w-full md:w-[calc(100%-250px)] z-50'>
                        <Navbar breadcrumbs={breadcrumbs} />
                    </div>
                    <div className='px-3 mt-[80px] md:ml-[10px] md:mt-[50px] mb-7 md:mb-0'>
                        <div className='font-semibold text-xl'>{this.props.t("Policy.Privacy Policy")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Policy.Privacy Policy1")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Privacy Policy Changes")}</div><div className='my-1'>{this.props.t("Policy.Privacy Policy Changes1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Consent and Agreement")}</div><div className='my-1'>{this.props.t("Policy.Consent and Agreement1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Use of the Website")}</div><div className='my-1'>{this.props.t("Policy.Use of the Website1")}</div></div></li>
                            </ol>
                        </div>
                        <div className='my-2'>
                            <ol className='list-decimal ml-4 text-sm'>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Collection Personally Information")}</div>
                                <div className='my-1'>{this.props.t("Policy.Collection Personally Information1")}</div>
                                <div className='my-1'>{this.props.t("Policy.Collection Personally Information2")}</div>
                                <div className='my-1'>{this.props.t("Policy.Collection Personally Information3")}</div>
                                <div className='my-1'>{this.props.t("Policy.Collection Personally Information4")}</div>
                                <div className='my-1'>{this.props.t("Policy.Collection Personally Information5")}</div>
                                <div className='my-1'>{this.props.t("Policy.Collection Personally Information6")}</div>
                                <div className='my-1'>{this.props.t("Policy.Collection Personally Information7")}</div>
                                <div className='my-1'>{this.props.t("Policy.Collection Personally Information8")}</div>
                                <div className='my-1'>{this.props.t("Policy.Collection Personally Information9")}</div>
                                </div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Profile Data")}</div>
                                <div className='my-1'>{this.props.t("Policy.Profile Data1")}</div>
                                <div className='my-1'>{this.props.t("Policy.Profile Data2")}</div>
                                <div className='my-1'>{this.props.t("Policy.Profile Data3")}</div>
                                <div className='my-1'>{this.props.t("Policy.Profile Data4")}</div>
                                <div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Cookies")}</div><div className='my-1'>{this.props.t("Policy.Cookies1")}</div>
                                </div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Sharing of Personal Information")}</div>
                                <div className='my-1'>{this.props.t("Policy.Sharing of Personal Information1")}</div>
                                <div className='my-1'>{this.props.t("Policy.Sharing of Personal Information2")}</div>
                                <div className='my-1'>{this.props.t("Policy.Sharing of Personal Information3")}</div>
                                <div className='my-1'>{this.props.t("Policy.Sharing of Personal Information4")}</div>
                                </div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Links to Other Sites")}</div><div className='my-1'>{this.props.t("Policy.Links to Other Sites1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Security Precautions")}</div><div className='my-1'>{this.props.t("Policy.Security Precautions1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Choice/Opt-Out")}</div><div className='my-1'>{this.props.t("Policy.Choice/Opt-Out1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Advertisements")}</div><div className='my-1'>{this.props.t("Policy.Advertisements1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Your Consent")}</div><div className='my-1'>{this.props.t("Policy.Your Consent1")}</div><div className='my-1'>{this.props.t("Policy.Your Consent2")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Grievance Officer")}</div>
                                <div className='my-1'>{this.props.t("Policy.Grievance Officer1")}</div>
                                <div className='my-1'>{this.props.t("Policy.Grievance Officer2")}</div>
                                <div className='my-1'>{this.props.t("Policy.Grievance Officer3")}</div>
                                <div className='my-1'>{this.props.t("Policy.Grievance Officer4")}</div>
                                <div className='my-1'>{this.props.t("Policy.Grievance Officer5")}</div>
                                <div className='my-1'>{this.props.t("Policy.Grievance Officer6")}</div>
                                <div className='my-1'>{this.props.t("Policy.Grievance Officer7")} <Link className='text-blue-500'>{this.props.t("Policy.Grievance Officer8")}</Link></div>
                                </div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Questions")}</div><div className='my-1'>{this.props.t("Policy.Questions1")}</div></div></li>
                            </ol>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}
export default withTranslation()(Policy);